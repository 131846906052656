import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";

function SampleNextArrow(props) {
    const { className, onClick } = props;
    return (
        <div
            className={className}
            onClick={onClick}
        />
    );
}

function SamplePrevArrow(props) {
    const { className, onClick } = props;
    return (
        <div
            className={className}
            onClick={onClick}
        />
    );
}

const Testimonial = () => {
    var settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: false,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1.2,
                slidesToScroll: 1,
                arrows: false
              }
            }
          ]
    };
    return (
        <div className="testimonial-wrapp gap-bottom gb">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <h2 className="title text-center theme-color-2">Customer feedback </h2>
                        <div className="t-carousel gap-top-sm">
                            <Slider {...settings}>
                                <div className="slick-item">
                                    <div>
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text unchanged.</p>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <Link to="/home" className="btn-w color1 sm text-start">Mr Dimitri B</Link>
                                            {/* <div className="text-sm">Nov 2020 </div> */}
                                        </div>
                                    </div>
                                    <Link to="/home" className="btn-w color2 sm text-start via-t-pilot pe-2 black-text">March 2023</Link>
                                </div>
                                <div className="slick-item">
                                    <div>
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text unchanged.</p>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <Link to="/home" className="btn-w color1 sm text-start">Mr Dimitri B</Link>
                                            {/* <div className="text-sm">Nov 2020 </div> */}
                                        </div>
                                    </div>
                                    <Link to="/home" className="btn-w color2 sm text-start via-t-pilot pe-2 black-text">March 2023</Link>
                                </div>
                                <div className="slick-item">
                                    <div>
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text unchanged.</p>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <Link to="/home" className="btn-w color1 sm text-start">Mr Dimitri B</Link>
                                            {/* <div className="text-sm">Nov 2020 </div> */}
                                        </div>
                                    </div>
                                    <Link to="/home" className="btn-w color2 sm text-start via-t-pilot pe-2 black-text">March 2023</Link>
                                </div>
                                <div className="slick-item">
                                    <div>
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text unchanged.</p>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <Link to="/home" className="btn-w color1 sm text-start">Mr Dimitri B</Link>
                                            {/* <div className="text-sm">Nov 2020 </div> */}
                                        </div>
                                    </div>
                                    <Link to="/home" className="btn-w color2 sm text-start via-t-pilot pe-2 black-text">March 2023</Link>
                                </div>
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Testimonial;