import { Button } from "react-bootstrap";
import './FormActionButton.css'

export default function FormActionButton({ onBack, onNext, onSubmit, backTitle = 'Back', nextTitle = 'Next', submitTitle = 'Submit' }) {

    return (
        <div className="row gx-2 steps action-button mt-4">
            {onBack && <div className="col-md-6 text-center mx-auto"><a className="btn-w black w-100" onClick={onBack}>{backTitle}</a></div>}
            {onNext && <div className={!onBack && !onSubmit ? "" : "col-md-6 text-center mx-auto"}><a className="btn-w color3 w-100" onClick={onNext}>{nextTitle}</a></div>}
            {onSubmit && <div className="col-md-6 text-center mx-auto"><a className="btn-w color3 w-100" onClick={onSubmit}>{submitTitle}</a></div>}
        </div>
    )
}