import React, { useEffect } from "react";
import Header from "./Header";
import Testimonial from "./Testimonial";
import ConatctBlock from "./ConatctBlock";
import Footer from "./Footer";
import InnerBanner from "./InnerBanner";
import FAQ from "./FAQ";

const ConatctPage = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return(
        <div className="faq-page-wrapp">
            <Header/>
            <InnerBanner pageName="Contact"/>
            <ConatctBlock />
            <Testimonial />
            <Footer />
        </div>
    )
}

export default ConatctPage;