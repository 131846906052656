import React, { useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";

const ComplaintsProcedures = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (
        <div className="au-wrapp">
            <Header bg='#EAEAEA' />
            <div className="cp-wrapp gap">
                <div className="container">
                    <h2 className="title text-center">ATC Complaints policy V1.0 </h2>
                    <div className="gap-top-sm">
                        <div className="mb-3">
                            Our clients are important to Air Travel Claim. We want to exceed our customers’ expectations every time; however there will be occasions where we may fall short in some way. If this happens we want to know, and we can assure you that we take every complaint received very seriously and deal with them in accordance with our internal complaints procedure which is designed to resolve our clients’ concerns quickly and efficiently.
                        </div>
                        <h3 className="title">How can I make a complaint?</h3>
                        <div className="mb-1">
                            Air Travel Claim, 84 Salop Street, Wolverhampton WV3 0SR.</div>
                        <div className="mb-1">Tel: 01902 939000</div>
                        <div className="mb-3">Complaints can also be sent by e-mail to: compliance@airtravelclaim.com.co.uk
                        </div>
                        <h3 className="title">What happens next?</h3>
                        <div className="mb-3">
                            You will receive an acknowledgment either in writing or by e-mail within 5 working days of receipt. Our team will then investigate your concerns and a response will be issued within 8 weeks. Where we decide that redress is appropriate, we will provide you with fair compensation for any acts or omissions for which we are responsible and will comply with any offer of redress which you accept. Appropriate redress will not always involve financial redress. If we do not hear from you within 14 days of receiving our response, we will assume that your complaint has been resolved and your file will be referred back to the relevant department.
                        </div>

                        <h3 className="title">I’m not satisfied with the response…What can I do?</h3>
                        <div className="mb-3">
                            If you do not feel that your complaint has been resolved satisfactorily please write a letter to explain why and ask for a further review. Please write to:</div>
                        <div className="mb-3">Air Travel Claim, 84 Salop Street, Wolverhampton WV3 0SR. Tel: 01902 954758
                        </div>

                        <h3 className="title">What happens next?</h3>
                        <div className="mb-3">Your case will be reviewed by the Senior Manager and a response will be sent within 8 weeks from the date we received your first complaint. This is the final stage of our internal complaints procedure.</div>

                        <h3 className="title">If I remain dissatisfied, who can I write to?</h3>
                        <div className="mb-3">If you are not satisfied with our response, or if a complaint is not resolved after 8 weeks, you may refer the complaint to:
                            Financial Ombudsman Service Exchange Tower London E14 9SR</div>
                        <div className="mb-3">
                            Website: www.financial-ombudsman.org.uk <br />
                            Email: complaint.info@financial-ombudsman.org.uk <br />
                            Phone: 0800 023 4567 or 0300 123 9123
                        </div>

                        <div className="mb-3">Any complaint must be referred to the Financial Ombudsman within 6 months of the date of our final written response.</div>

                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default ComplaintsProcedures;