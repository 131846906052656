import { useEffect } from 'react';

function useBackListener(callback) {
  useEffect(() => {
    const handleBackButton = () => {
      callback();
    };

    window.onpopstate = handleBackButton;

    return () => {
      window.onpopstate = null;
    };
  }, [callback]);
}

export default useBackListener;