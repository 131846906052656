import React from 'react'
import { useEffect, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { COMPENSATE_SCREEN, SEARCH_AIRPORT_ENDPOINT } from "../../constants";
import FormActionButton from "../shared/FormActionButton";
import AsyncSelect from 'react-select/async';
import axios from "axios";
import Button_ from '../shared/Button';
import ConnectingFlightSelection from '../shared/ConnectingFlightSelection';
import ListRadioOptions from '../shared/ListRadioOptions';


export default function ConnectingFlights({compensate, onNext, onBack}) {

    const intl = useIntl();
    const [stops, setStops] = useState(compensate.stops ?? [null])
    const [connectingFlights, setConnectingFlights] = useState(compensate.connectingFlights ?? [])
    const [affectedFlights, setAffectedFlights] = useState(compensate?.affectedFlights ?? '')
    const [isMissedConnection, setIsMissedConnection] = useState(compensate.isMissedConnection)
    const [hasBoardedReplacement, setHasBoardedReplacement] = useState(compensate.hasBoardedReplacement)
    const [isDestinationSame, setIsDestinationSame] = useState(compensate.isDestinationSame)
    const [stopsError, setStopsError] = useState(false)
    const [affectedFlightError, setAffectedFlightsError] = useState(false)
    const [isMissedConnectionError, setIsMissedConnectionError] = useState(false)
    const [lastLegmissed, setLastLegMissed] = useState(true)
    const [hasBoardedReplacementError, setHasBoardedReplacementError] = useState(false)
    const [isDestinationSameError, setIsDestinationSameError] = useState(false)





    const [flightsSelected, setFlightsSelected] = useState(false)

    const fetchAirportsData = async (search) => {
        const formatAirports = (data) => data?.map(airport => ({ label: airport.airportName + ', ' + airport.city + ', ' + airport.country, value: airport.airportCode }));
        if (search.length > 2) {
            return axios.post(SEARCH_AIRPORT_ENDPOINT(), { 
                "destination": search 
            }).then(data => formatAirports(data.data.message.data)).catch(_ => []);
        }
    }
    useEffect(() => {
        setConnectingFlights(connectingFlights => (
          connectingFlights.length === 0
            ? [{ departureAirport: compensate.departureAirport}]
            : connectingFlights
        ));
      }, [compensate]);

    useEffect(()=> {
        setFlightsSelected(false)
        setAffectedFlights('')
    },[])

    const flightNumberMatch = affectedFlights.match(/\d+/);
    const flightNumber = flightNumberMatch ? parseInt(flightNumberMatch[0]) : 0;
    
    const handleStopChange = (e, i) =>{
        setStopsError(false)
        const airports = [...stops]
        airports[i] = e
        setStops(airports)
        setConnectingFlights((prevFlights) => {
            const updatedFlights = [...prevFlights];
    
            // Update destinationAirport for the current index
            updatedFlights[i] = { ...updatedFlights[i], destinationAirport: e };
    
            // Update departureAirport for the next index if applicable
            if (i + 1 < updatedFlights.length) {
                updatedFlights[i + 1] = { ...updatedFlights[i + 1], departureAirport: e };
            } else if (i + 1 === updatedFlights.length) {
                // Add a new flight with departureAirport if the current index is the last one
                updatedFlights.push({ departureAirport: e });
            }
    
            return updatedFlights;
        });
    }

    const addAnotherStop = () => {
        setStops([...stops, null ]);
      };

      const deleteStop = (i) => {
        const newStopsArray = [...stops];
        const newConnecting = [...connectingFlights];
    
        // Remove stop at index i
        newStopsArray.splice(i, 1);
        
        // Remove connecting flight at index i
        if(stops[i] !== null){
        newConnecting.splice(i, 1);
    }
        // Update the departureAirport for the next connecting flight
        if (i < newConnecting.length) {
            const previousDestinationAirport = i > 0 ? newConnecting[i - 1].destinationAirport : null;
    
            // Remove destinationAirport property from the object at index i
            const updatedConnectingFlight = { ...newConnecting[i] };
            delete updatedConnectingFlight.destinationAirport;
    
            // Update the connectingFlights array with the modified object
            newConnecting[i] = { ...updatedConnectingFlight, departureAirport: previousDestinationAirport };
        }
    
        setStops(newStopsArray);
        setConnectingFlights(newConnecting);
    };

    //last leg error handling
    useEffect(() => {
        if(  flightNumber === connectingFlights.length){
            setLastLegMissed(true)
            setIsMissedConnection(null)
            setHasBoardedReplacement(null)
            setIsDestinationSame(null)
        }else{
            setLastLegMissed(false)
        }
    },[isMissedConnection, flightNumber, connectingFlights])
    

     // react-select input styles 
     const customStyles = {
        control: (base) => ({
            ...base,
            textAlign: 'center'
        }),
        dropdownIndicator: (base, state) => ({
          ...base,
          transition: 'all .2s ease',
          transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
          // Add any other custom styles you want for the dropdown indicator
        }),
        indicatorSeparator: (base) => ({
          ...base,
          display: 'none',
        }),
        dropdownIndicatorContainer: (base) => ({
          ...base,
          padding: '4px',
        }),
        customDropdownIndicator: {
          // Add any custom styles for the dropdown indicator
          // that won't affect other classes
        },
      };

  return (<>
    <div className="fdc-form gif-input">
        {!flightsSelected && <>
            <div className="heading d-flex justify-content-center align-items-center uplers-icon fdc-form">
                <img src="/uplers-icons/icon2.svg" alt="icon" />
                <h2 className="title">
                    <FormattedMessage 
                        id="Your connecting flight details"
                        defaultMessage="Your <strong>connecting</strong> flight details"
                        values={{
                            strong: (chunks) => <strong>{chunks}</strong>,
                        }}
                    />
                </h2>
            </div>
            <div className="col-md-12">
                <div className="box-message fw-semibold theme-color-1 pb-0">
                    <FormattedMessage id="Please confirm the airport where you changed flights"/>             
                </div>
            </div>
            <div className='row'>
                {stops.map((stop, i) => (
                    <div key={i}>
                        <br />
                        <AsyncSelect
                            placeholder={intl.formatMessage({ id: 'Type a city or Airport' })}
                            onChange={(e) => handleStopChange(e, i)}
                            loadOptions={fetchAirportsData}
                            value={stop}
                            styles={customStyles}
                            components={{
                                DropdownIndicator: (props) => (
                                <div className="custom-dropdown-indicator">
                                        <img src="/uplers-icons/flight-list-icon.svg" alt="departure-icon" />
                                </div>
                                ),
                            }}
                            isClearable
                        />
                        <div className='d-sm-flex align-items-center justify-content-between'>
                            {i === stops.length - 1 && (<>
                            {stopsError &&                                  
                            <div>
                                <span className="text-danger">
                                    <FormattedMessage id="Please enter a stop"/>
                                </span>
                            </div>}
                            <br />
                            <i><FormattedMessage id="If you have more than one stop, add another stop."/></i>
                            <Button_ 
                            label={'Add another stop'}
                            onClick={addAnotherStop}
                            className='mb-3'
                            style={{minWidth: '150px'}}
                            />
                            </>)}
                            {stops.length > 1 && i === stops.length - 1 && (
                                <Button_
                                    label={'Remove Stop'}
                                    onClick={() => deleteStop(i)}
                                    className='mb-3'
                                    style={{backgroundColor: 'red', minWidth: '150px'}}
                                />
                            )}
                        </div>
                    </div>
                ))}
                <p><FormattedMessage id={"Once you've added the stops click next"} /></p>
            </div>
        </>}
        {flightsSelected && connectingFlights?.length >= 2 && <>
            <div className="heading d-flex justify-content-center align-items-center uplers-icon fdc-form">
                <img src="/uplers-icons/icon2.svg" alt="icon" />
                <h2 className="title"><FormattedMessage id="Your flight disruption" /></h2>
            </div>
            <ConnectingFlightSelection
                className='radio-button w-100 d-inline-flex'
                items={[...connectingFlights]}
                onSelect={(val) =>{
                    setAffectedFlights(val)
                    setAffectedFlightsError(false)
                }}
                value={affectedFlights}

            />   
            {affectedFlightError && 
                        <div>
                            <span className="text-danger">
                                <FormattedMessage id="Please select an option"/>
                            </span>
                        </div>}
            <i><FormattedMessage id="If more than one flight was disrupted, please select the first flight that went wrong"/></i>
            {!lastLegmissed && <><div className="col-md-12">
                <div className="form-block">
                    <ListRadioOptions
                        label={intl.formatMessage({ id: "Did this delay cause you to miss your connecting flight?"})}
                        className="d-inline-block"
                        divStyle="full-width"
                        items={[{ text: intl.formatMessage({ id:"Yes"}), value: true }, { text: intl.formatMessage({ id:"No"}), value: false }]}
                        onSelect={(val) => {
                            setIsMissedConnection(val)
                            setIsMissedConnectionError(false)
                            if(val === false){
                                setHasBoardedReplacement(null)
                                setIsDestinationSame(null)
                            }
                        }}
                        value={isMissedConnection}
                    />
                    {isMissedConnectionError && 
                        <div>
                            <span className="text-danger">
                                <FormattedMessage id="Please select an option"/>
                            </span>
                        </div>
                    }
                    {isMissedConnection && <><ListRadioOptions
                            label={intl.formatMessage({ id: "Did you travel on a replacement flight offered by your airline?"})}
                            className="d-inline-block"
                            divStyle="full-width"
                            items={[{ text: intl.formatMessage({ id:"Yes"}), value: true }, { text: intl.formatMessage({ id:"No"}), value: false }]}
                            onSelect={(val) => {
                                setHasBoardedReplacement(val)
                                setHasBoardedReplacementError(false)
                                if(val === false){
                                    setIsDestinationSame(null)
                                }
                            }}
                            value={hasBoardedReplacement}
                        />
                        {hasBoardedReplacementError && 
                            <div>
                                <span className="text-danger">
                                    <FormattedMessage id="Please select an option"/>
                                </span>
                            </div>
                        }
                    </>}
                    {hasBoardedReplacement &&<> <ListRadioOptions
                        label={intl.formatMessage({ id: "Did your replacement flight take you to the correct destination?"}, { destinationAirport: <b>{compensate.destinationAirport?.label.split(",")[0]}</b> })}
                        className="d-inline-block"
                        divStyle="full-width"
                        items={[{ text: intl.formatMessage({ id:"Yes"}), value: false }, { text: intl.formatMessage({ id:"No"}), value: true }]}
                        onSelect={(val) => {
                            setIsDestinationSame(val)
                            setIsDestinationSameError(false)
                        }}
                        value={isDestinationSame}
                    />
                    {isDestinationSameError && 
                        <div>
                            <span className="text-danger">
                                <FormattedMessage id="Please select an option"/>
                            </span>
                        </div>
                    }
                    </>}                    

                </div>
            </div></>}
        </>}
        <div className="col-md-6 text-center mx-auto">

            <FormActionButton 
                onNext={() =>{
                    stops[0] === null ? setStopsError(true) : setStopsError(false)
                    
                    if(!flightsSelected && stops[0] !== null){
                        setConnectingFlights((prevFlights) => {
                            const updatedFlights = [...prevFlights];
                        
                            // Check if flightSelected is true and the last index is not already updated
                            if (updatedFlights.length > 0 && !updatedFlights[updatedFlights.length - 1].destinationAirport) {
                              updatedFlights[updatedFlights.length - 1] = { ...updatedFlights[updatedFlights.length - 1], destinationAirport: compensate.destinationAirport
                            };
                            }
                        
                            return updatedFlights;
                          });
                        setFlightsSelected(true)
                    }else{
                        !affectedFlights ? setAffectedFlightsError(true) : setAffectedFlightsError(false)
                        isMissedConnection !== true && isMissedConnection !== false ? setIsMissedConnectionError(true) : setIsMissedConnectionError(false)
                        isMissedConnection === true && flightNumber === connectingFlights.length ? setLastLegMissed(true) : setLastLegMissed(false)
                        hasBoardedReplacement !== true && hasBoardedReplacement !== false ? setHasBoardedReplacementError(true) : setHasBoardedReplacementError(false)
                        hasBoardedReplacement === true && isDestinationSame !== true && isDestinationSame !== false ? setIsDestinationSameError(true) : setIsDestinationSameError(false)

                        if((affectedFlights && lastLegmissed) || (affectedFlights && !lastLegmissed && isMissedConnection === false) 
                            || (affectedFlights && !lastLegmissed && isMissedConnection && hasBoardedReplacement === false )
                            || (affectedFlights && !lastLegmissed && isMissedConnection && hasBoardedReplacement && (isDestinationSame === true  || isDestinationSame === false)) ){
                        onNext({
                            stops,
                            connectingFlights,
                            affectedFlights,
                            numberOfFlights: connectingFlights?.length,
                            isMissedConnection,
                            hasBoardedReplacement,
                            isDestinationSame
                        }, (COMPENSATE_SCREEN.AFFECTED_FLIGHT_DATE))}
                    }
                }}
                onBack={ () =>{
                    onBack((COMPENSATE_SCREEN.START_DESTINATION))
                }}
            />
            <br/>
            <img src={`/img/light-bulb.svg`} className="light-bulb" alt="Light bulb"/><b className='fst-italic'><FormattedMessage id="CONNECTING_FLIGHTS encouragement text"/></b>
        </div>
        
    </div>
  </>)
}