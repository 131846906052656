import React from 'react'

export default function MlmTerms() {
  return (
    <div>
        <h1 id='my-law-matters-terms-of-engagement-agreement'>My Law Matters Terms of Engagement (Agreement)</h1>
<p>Fentimans Solicitors and Fentiman Legal Limited is part of My Law Matters (&quot;MLM&quot;) is registered in England (Company No. 12661534) and is authorised and regulated by the Solicitors Regulation Authority (SRA number: 800557). This document sets out the Terms of Engagement for the appointment of My Law Matters to act for You.</p>
<h2 id='definitions-and-interpretation'>Definitions and Interpretation</h2>
<p>&quot;Airline&quot; means the business to whom the Letter of Authority (LOA) is addressed, who you are pursuing a Claim(s) against.</p>
<p>&quot;Agreement&quot; means the Terms of Engagement set out herein.</p>
<p>&quot;Agreement to Proceed&quot; means the formal engagement alongside this Agreement by You for MLM to pursue the Claims Services.</p>
<p>&quot;Cancellation Period&quot; means 14 days after the date that You sign the Agreement to Proceed.</p>
<p>&quot;Claim(s)&quot; means Your Claim(s) against the Airline in relation to you flight delay Claim(s).</p>
<p>&quot;Claims Services&quot; means preparing, submitting, and negotiating Your Claim(s) to/with the Airline. Where appropriate, this may include commencing legal proceedings.</p>
<p>&quot;Compensation&quot; means any sums offered, paid or given in respect of a settlement, goodwill gesture, rebate or any other payment associated with the Claim(s).</p>
<p>&quot;Court&quot; means a court in HM Courts Service.</p>
<p>&quot;Disbursements&quot; means payments MLM make on Your behalf to others involved in the case. These may include Court fees, barrister fees and/or courier charges. This is not an exhaustive list.</p>
<p>&quot;Fee(s)&quot; means the amount You will have to pay to MLM for the Claims Services.</p>
<p>&quot;FTF&quot; means Foreign Transaction Fee.</p>
<p>&quot;Instructions&quot; means Your formal Instructions to Us to commence the Claims Services by signing and returning an Agreement to Proceed.</p>
<p>&quot;Letter of Authority&quot; means the document that will be sent to the Airline that evidences Your authority for MLM to act on Your behalf.</p>
<p>&quot;MLM&quot; means My Law Matters. Any reference to ’We’, ’Us’ or ’Our’ in this Agreement shall be taken to be a reference to MLM.</p>
<p>&quot;Lien&quot; MLM’s right to keep all papers, documents, money or other property held on your behalf until We receive all money due to us.</p>
<p>&quot;Terms&quot; means these Terms of Engagement.</p>
<p>&quot;You/Your&quot; means the individual whose details are detailed in the Letter of Authority. Unless the context otherwise requires, words in the singular include the plural and in the plural include the singular.</p>
<h2 id='claims-services'>Claims Services</h2>
<h3 id='your-responsibilities'>Your Responsibilities</h3>
<p>MLM will not process Your Claim(s) until We receive Your Instructions.</p>
<p>You acknowledge that You could complain directly to the Airline Yourself or with the assistance of any legal expenses insurance that You may have, with the ability to take matters further to the Airline’s Alternative Dispute Resolution Scheme or Court.</p>
<p>By giving Your Instructions to MLM You acknowledge that You are granting MLM the exclusive right to deal with the Claim(s), unless otherwise agreed in writing by You and MLM.</p>
<p>You agree you must not make any direct or indirect settlement of your claim with the airline other than through Us.</p>
<p>You must not instruct any other representative to pursue your compensation claim against the Airline without first terminating this contract.</p>
<p>You must not communicate with the Airline, other than through Us.</p>
<p>You acknowledge that tourist vouchers or offers of any services may not be accepted as Compensation by MLM.</p>
<p>You must inform us immediately of any payment offered or received from the airline.</p>
<p>You acknowledge that it is the sole discretion of MLM whether to accept or reject any offer of a partial Compensation payment.</p>
<p>To comply with Money Laundering Regulations We may be required to verify Your identity(ies) and address(es). By giving Your Instructions, You acknowledge that You are giving consent to Us to undertake an electronic check to verify Your identity(ies) and address(es), if required. This process may involve searches with credit reference, fraud prevention agencies and the electoral register. The agencies will record the details of the search but the electronic check will have no adverse effect on Your credit rating. Subject to these checks, We may require You to supply certain documents and/or We may refuse, at Our discretion, to act for You without giving reason.</p>
<p>You understand that by issuing Your Instructions, that the Airline may investigate all Claim(s) under the Instructions and that any successful Claim(s) will be subject to a Fee(s) plus VAT, as set out in clause 3.</p>
<p>You may be asked to provide all relevant information in relation to the Claim(s) that You have in Your possession. You will ensure that any information provided is accurate and true to the best of Your knowledge.</p>
<p>You will deal promptly with requests by MLM for authority, information, documents or further requests.</p>
<p>Where We believe that you have a Claim(s), We will submit a detailed Claim to the Airline and/or the Court upon Your Instructions.</p>
<p>You will inform MLM promptly of any relevant matters affecting the Claim(s), such as direct contact from the Airline.</p>
<p>MLM will use reasonable endeavours to obtain Compensation for the Claim(s) pursued.</p>
<p>MLM will promptly notify You if MLM decides not to pursue Your Claim(s) and cancel any Agreement to Proceed. This decision shall remain in MLM’s absolute discretion.</p>
<p>MLM will promptly notify You of the outcome of the Claim(s).</p>
<p>Any Compensation paid directly to MLM will be held on Your behalf in a client account.</p>
<p>MLM will normally account to You for any interest on balances held on Your behalf in MLM’s client account at the conclusion of a matter. Interest will be paid only where the amount of interest calculated exceeds £20. Please contact MLM for a copy of the firm’s policy on monies held in client account.</p>
<p>MLM reserves the right to assign the Agreement to Proceed and all rights under it and to subcontract to others all or any of Our obligations under it. The Agreement to Proceed is personal to You and is not assign able by You except to Your
personal representatives.</p>
<h3 id='mlm-fees'>MLM Fee(s)</h3>
<p>If MLM undertakes the Claims Services and is not successful in obtaining Compensation then You owe MLM nothing.</p>
<p>If MLM is successful in obtaining a reasonable offer or payment of Compensation then You owe MLM the Fee of 35% of the Compensation that YOU have been offered or received. This means for example that if You receive Compensation of £520, the
Fee would be £182 plus VAT.</p>
<p>If we have to issue legal proceedings an additional 15% will be charged to cover the additional costs involved.</p>
<p>In all cases we charge an Administration Fee of £15 +VAT per passenger.</p>
<p>All fees are subject to VAT at the prevailing rate.</p>
<p>You agree to authorise the Airline to remit the Compensation to MLM and agree that MLM shall deduct the Fee(s) plus VAT before paying the balance of the Compensation to You.</p>
<p>MLM will, as soon as is reasonably practicable following the settlement of a Claim(s) and the payment of Compensation by the Airline to MLM, pay You the balance of the Compensation, after deducting the Fee(s), any administration charge plus VAT. For the avoidance of doubt where You have more than one successful Claim against one or more Airlines, MLM may deduct from any Compensation paid the Fee(s) plus VAT payable in respect of each of the Claims from the Compensation received for any one of those Claims.</p>
<p>Fee(s) plus VAT become due at the point that either MLM or You are informed of an offer of Compensation and payable from the date You receive Your Compensation. If the Airline offers or pays Compensation to You directly, You agree to notify MLM immediately (and within seven days at the latest) and to provide the details MLM needs to calculate the Fee(s). For the avoidance of doubt, should You reject a reasonable offer of Compensation the Fee plus VAT remains due on the sum offered and payable by You within seven days of receiving the offer of compensation.</p>
<p>If the airline or your nominated financial institution to receive Your Compensation, applies an FTF, You are responsible for the cost of this fee.</p>
<p>MLM will invoice You for the Fee(s) plus VAT which will become immediately payable by You to MLM from the date You receive Your Compensation.</p>
<p>If You fail to pay the Fee(s) plus VAT due and payable in accordance with these Terms, We reserve the right to cancel all
other active Claims.</p>
<p>In the event that a fee due to us by you remains unpaid after 45 days then late payment interest will be calculated at 8% per annum from the date that the fee became due until the date payment is made. We reserve the right to charge for costs and expenses incurred in recovering unpaid Fees plus VAT where an offer or payment of Compensation is made from a Company to You. These Terms shall continue to operate until all Claims where a Fee(s) plus VAT is payable have been settled.</p>
<p>Where fees remain unpaid and MLM are forced to either take court action or appoint a debt collection agency to recover outstanding monies, MLM reserve the right to increase the amount owed by the amount equivalent to the cost of that recovery. A court may award additional cost and/or statutory interest at the rate of 8% above the Bank of England rate.</p>
<h2 id='cancellation-rights-and-charges'>Cancellation Rights and Charges</h2>
<p>You may cancel Your Claim(s) at any time by using the Cancellation Form available at www. mylawmatters.co.uk/cancellation.
Alternatively, You may request cancellation by contacting Your usual contact at MLM or to MLM directly in writing, by telephone 01902 955042 or via email flight@ mylawmatters.co.uk.</p>
<p>By giving Your Instructions to MLM, You expressly consent to MLM commencing the Claims Services immediately and prior to the expiration of the Cancellation Period.</p>
<p>If You cancel Your Claim(s) within the Cancellation Period You will owe MLM nothing, unless You have received a reasonable offer of Compensation within this period. In this case, MLM shall be entitled to charge You a cancellation charge equal to the Fee(s) plus VAT that would be payable under Fees clause above.</p>
<p>If You cancel the Claim(s) at any time after the Cancellation Period, MLM may charge a cancellation charge which will be reasonable and proportionate to the work done and the costs incurred by MLM up to the point of cancellation. This cancellation charge will be the time spent on Your Claim(s) by MLM charged at £150 plus VAT per hour.</p>
<p>MLM may at its sole discretion decide not to proceed with a Claim and cancel the Agreement to Proceed at any time, but MLM must act reasonably in taking such a decision and You will owe MLM nothing.</p>
<h2 id='your-personal-data'>Your Personal Data</h2>
<p>We take the privacy of Your personal information seriously. We will only use the personal information You provide to Us as outlined both in this clause and in Our Privacy Policy which can be viewed at <a href='http://www.mylawmatters.co.uk/privacy-policy' target='_blank' class='url'>www.mylawmatters.co.uk/privacy-policy</a>. We
ask that You read Our Privacy Policy carefully and, if required, a hard copy is available upon request. During the Claims Services Your personal information will be used to: Provide the Claims Services;</p>
<p>Tell You about similar products or services relating to investment and financial claims and other claims, by email, SMS (text message), post and/or telephone, but You can opt out of receiving these, or a specific method of communication, at any time by contacting Us.</p>
<p>Otherwise than in relation to the Claims Services and providing Our legal services to You in relation to the Claim(s), We will only provide Your personal Information to third party organisations with Your explicit consent..</p>
<p>We will update You throughout the Claims Services by SMS (text message), email, telephone and/or post.</p>
<p>After completing the Claims Services We will be entitled to keep any of Your papers and documents that We hold while there is still money owed to Us for Fees plus VAT. We will keep Our file of Your papers for up to seven years in electronic
form after which time We will destroy them.</p>
<h2 id='disclaimer'>Disclaimer</h2>
<p>You acknowledge that any estimate of Compensation given to You is an estimate only. The success of any Claim(s) depends on Your individual circumstances.</p>
<h2 id='complaints'>Complaints</h2>
<p>Governing Law and Professional Indemnity Insurance</p>
<p>You can make a complaint about Our service via MLM’s internal complaints procedure by
email:<a href='mailto:flightcomplaints@mylawmatters.co.uk' target='_blank' class='url'>flightcomplaints@mylawmatters.co.uk</a>, by telephone on 01902 229346 or by post to My Law Matters, 84 Salop Street, Wolverhampton, West Midlands, WV3 0SR. You can also find a copy of Our complaints procedure at <a href='http://www.mylawmatters.co.uk'>www.mylawmatters.co.uk</a>.</p>
<p>Should You remain unhappy You may refer Your complaint, within six months of the date of Our final response, to the Legal Ombudsman Service, by email: <a href='mailto:enquiries@legalombudsman.org.uk' target='_blank' class='url'>enquiries@legalombudsman.org.uk</a>, by phone on 0300 555 0333 or post to Legal Ombudsman, PO Box 6806, Wolverhampton, WV1 9WJ. In addition, the Legal Ombudsman expects complaints to be made to them within one year of the date of the act or omission about which you are concerned or within one year of you realising there was a concern. If you have any concerns about any misconduct or breach of the SRA Code of Conduct by our firm such as taking or losing your money, dishonesty or discrimination, you can also report the matter to our regulatory body, the Solicitors Regulation Authority. Their details are:</p>
<p>The Solicitors Regulation Authority,
The Cube,
Wharfside Street Birmingham,
B1 1RN
0370 606 2555
<a href='http://www.sra.org.uk/consumers/problems/report-solicitor'>www.sra.org.uk/consumers/problems/report-solicitor</a></p>
<p>Each party irrevocably agrees that any proceedings relating to any dispute or claim (including non-contractual disputes or claims) arising out of or in connection with the Agreement to Proceed or its subject matter or formation shall be governed by and construed in accordance with English law and the parties irrevocably submit to the jurisdiction of the Courts of England and Wales.</p>
<p>In agreeing these Terms and Conditions you confirm you</p>
<p>1.Have carefully this Agreement to Proceed and the Terms of Engagement</p>
<p>2.Authorise Air Travel Claim to release any information requested by My Law Matters for the purposes of considering my Claim, including my personal data, contact information and any relevant information from the Airline.</p>
<p>3.You further instruct MLM to:</p>
<p>a) Submit a Letter of Claim to the Airline for each Claim(s) identified;</p>
<p>b) Negotiate with the Airline to obtain Compensation for each Claim identified and accept offers of Compensation made by the Airline on either my behalf and any additional passengers;</p>
<p>c) Submit a Complaint to the Airline’s Alternative Dispute Resolution Scheme.</p>
<p>d) Issue a Claim to Court on both mine and any additional passenger(s) behalf.</p>
<p>e) Where you have provided details of additional passengers to be included in the claim you are warranting and representing to us that: (i) You are their agent and they are your principal (ii) You have full power and authority to provide those details to us and (iii) full power and authority to bind the other passengers to this contract as parties to it, so that they become your clients and are bound by the terms of this contract, including the obligations outlined under Your Responsibilities.</p>
<p>V2.5</p>
<p>&nbsp;</p>
    </div>
  )
}
