import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { COMPENSATE_SCREEN, EACH_PASSENGER_CLAIM } from "../../constants";
import FormActionButton from "../shared/FormActionButton";
import TextArea from "../shared/TextArea";
import Label from "../shared/Label";
import ListRadioOptions from "../shared/ListRadioOptions";
import parse from 'html-react-parser';

export default function OtherComment({ onBack, onSubmit, compensate, isSubmitted, updateAction }) {
    const [paidForTaxi, setPaidForTaxi] = useState(compensate.paidForTaxi)
    const [paidForHotel, setPaidForHotel] = useState(compensate.paidForHotel)
    const [paidForFood, setPaidForFood] = useState(compensate.paidForFood)
    const [comment, setComment] = useState(compensate.comment)
    const intl = useIntl();
    const deniedBoardingText = "being <b>denied boarding</b> on your"
    const otherReasonText = `your <b>${compensate.delayReason3.toLowerCase()}</b>`
    const flightDisruptionText = () =>{
        if(compensate?.delayReason3 === "Denied Boarding" ){
            return parse(deniedBoardingText) 
        }else{
            return parse(otherReasonText)
        }
    }
    
    return (
        <>
            <div className="heading fdc-form d-flex justify-content-center align-items-center uplers-icon"> 
                <img src="/uplers-icons/icon2.svg" /> 
                <h2 className="title">
                    { intl.formatMessage({id:"Due to the flight disruption did you pay for any of the following?"},
                        {flightDisruption: flightDisruptionText() ,
                        departureAirport: <b>{compensate?.departureAirport?.label.split(",")[1]}</b>,
                        destinationAirport: <b>{compensate?.destinationAirport?.label.split(",")[1]}</b>}
                    )}
                </h2>
            </div>
            <div className="fdc-form gif-input">
                <div className="row">
                    <div className="col-md-12">
                        <div className="form-block">
                            <ListRadioOptions
                                label={intl.formatMessage({id:"Did you pay for a Taxi?"})}
                                items={[{ text: intl.formatMessage({ id:"Yes"}), value: true }, { text: intl.formatMessage({ id:"No"}), value: false }]}
                                onSelect={setPaidForTaxi}
                                value={paidForTaxi}
                                className="d-inline-block"
                                divStyle="full-width"
                            />
                            <ListRadioOptions
                                label={intl.formatMessage({id:"Did you pay for a Hotel?"})}
                                items={[{ text: intl.formatMessage({ id:"Yes"}), value: true }, { text: intl.formatMessage({ id:"No"}), value: false }]}
                                onSelect={setPaidForHotel}
                                value={paidForHotel}
                                className="d-inline-block"
                                divStyle="full-width"
                            />
                            <ListRadioOptions
                                label={intl.formatMessage({id:"Did you pay for Food?"})}
                                items={[{ text: intl.formatMessage({ id:"Yes"}), value: true }, { text: intl.formatMessage({ id:"No"}), value: false }]}
                                onSelect={setPaidForFood}
                                value={paidForFood}
                                className="d-inline-block"
                                divStyle="full-width"
                            />
                            <TextArea
                                placeholder={intl.formatMessage({ id: "Space for free text narrative comments" })}
                                onChange={(e) => setComment(e.target.value)}
                                value={comment}
                                style={{ width: '100%', boxShadow: 'inset 0 2px 2px' }}
                                rows={'6'}
                            />
                        </div>
                    </div>
                    <div className="col-md-6 text-center mx-auto">
                        <FormActionButton
                            onSubmit={() => {
                                if (!isSubmitted) {
                                    updateAction({
                                        paidForFood,
                                        paidForHotel,
                                        paidForTaxi,
                                        comment
                                    }, (COMPENSATE_SCREEN.OTHER_COMMENTS + 1))
                                }
                            }}
                            onBack={() => {
                                onBack(COMPENSATE_SCREEN.OTHER_COMMENTS - 1)
                            }}
                            submitTitle={intl.formatMessage({ id: "Next" })}
                        />
                        <br/>
                        <img src={`/img/light-bulb.svg`} className="light-bulb" alt="Light bulb"/><b className='fst-italic'><FormattedMessage id="OTHER COMMENTS encouragement text"/></b>
                    </div>
                </div>
            </div>


        </>
    )
}