import { useEffect, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { COMPENSATE_SCREEN, GET_FLIGHT_ENDPOINT } from "../../constants";
import FormActionButton from "../shared/FormActionButton";
import DepartureDate from "../shared/DepartureDate.js/DepartureDate";


export default function DirectFlightDate({ compensate, onNext, onBack, isSubmitted, areDetailsSubmitted, setAreDetailsSubmitted}) {
    const intl = useIntl();

    const nextPage = (FlightList) => {
        if(FlightList.length > 0){
            return COMPENSATE_SCREEN.DIRECT_FLIGHT_SEARCH
        } else {
            return COMPENSATE_SCREEN.DIRECT_FLIGHT_DATE
        }

    }
    
    const previousPage = () => {
        return COMPENSATE_SCREEN.START_DESTINATION
    }

    return (
        <DepartureDate
            onBack={onBack}
            onNext={onNext}
            compensate={compensate}
            isSubmitted={isSubmitted}
            areDetailsSubmitted={areDetailsSubmitted}
            setAreDetailsSubmitted={setAreDetailsSubmitted}
            departure={compensate.departureAirport}
            destination={compensate.destinationAirport}
            nextPage={nextPage}
            previousPage={previousPage}
            directFlight
            
        />
        )
    }
    