import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { COMPENSATE_SCREEN, EACH_PASSENGER_CLAIM  } from "../../constants";
import { getCompensationPayloadForCRM} from "../../containers/FlightCompensation/mapper"
import FileInput from "../shared/FileInput";
import FormActionButton from "../shared/FormActionButton";
import Text from "../shared/Text";
import ListRadioOptions from "../shared/ListRadioOptions";
import Label from "../shared/Label";

export default function Evidence({ onBack, onNext, compensate, submitAction, updateAction }) {
    const [evidence, setEvidence] = useState(compensate.evidence)
    const [evidenceType, setEvidenceType] = useState(compensate.evidenceType)
    const [bookingReference, setBookingReference] = useState(compensate.bookingReference === true || compensate.bookingReference === "true" ? '': compensate.bookingReference)
    const [evidenceMime, setEvidenceMime] = useState(compensate.evidenceMime)
    const [evidenceName, setEvidenceName] =  useState(compensate.evidenceName)
    const [hasBoardingPass, setHasBoardingPass] = useState(false)
    const [bookingrefernceError, setBookingReferError] = useState(false)
    const [evidenceError, setEvidenceError] = useState(false)
    const [clients, setClients] = useState(compensate.clients)

    
    const intl = useIntl();

    const claimWorth = () =>{
        const numberOfClients = clients?.length+1 
        const totalClaimWorth = numberOfClients* EACH_PASSENGER_CLAIM;
       return  totalClaimWorth
   }

    const bookref = (event) =>{
        setBookingReference(event)
        event === '' || event === undefined ? setBookingReferError(true) : setBookingReferError(false)
    }
    const upload = (e) =>{
        setEvidenceType(e)
        setEvidenceError(false)
    }
        return (
        <>
            <div className="heading fdc-form d-sm-flex justify-content-center align-items-center uplers-icon">
                <img src="/uplers-icons/icon1.svg" alt="passenger-icon" className="d-block m-sm-0 m-auto" /> 
                <h2 className="title"><FormattedMessage id="We now need to gather important details about your flight" /></h2>
            </div>
            <div className="fdc-form gif-input">
                <div className="row">
                    <div className="col-md-12">
                        <div className="form-block">
                            <Text
                                label={intl.formatMessage({ id: "Before you go, please provide us with your booking reference number." })}
                                id="Booking Reference"
                                onChange={(e) => bookref(e.target.value)}
                                value={bookingReference}
                                required={false}
                                help={"A booking reference is a unique code specifc to an individual reservation. It is also known as a Record/Booking Locator (or RecLoc), PNR Code, confrmation number or reference number. It can be found on your tickets, booking confrmation or travel documentation. It is is 6 characters long and can contain letters or numbers. For example 'ZYGPPZ' or 'ZP26JX' or '346718'."}
                            />
                            {bookingrefernceError?
                                 <div>
                                    <span className="text-danger">
                                        <FormattedMessage id="Please enter the booking reference Number"/>
                                    </span>
                                </div>
                                :<></>
                              }


                        </div>
                    </div>
                    {/* <div className="col-md-12">
                        <div className="form-block">
                            <div className="info fw-semibold theme-color-1">
                                <FormattedMessage id="Please upload your boarding pass or e-ticket" />
                            </div>
                        </div>
                    </div> */}
                    {/* !process.env.REACT_APP_PRODUCTION && <div className="col-md-12">
                        <div className="form-block">
                        <pre>
                                {JSON.stringify(getCompensationPayloadForCRM(compensate), null, 2)}
                            </pre>
                        </div>
                    </div>*/}
                    <div className="col-md-12">
                        <div className="form-block">
                            <Label label={intl.formatMessage({ id:"We also need your boarding pass or e-ticket, as these documents are essential to confirm your flight details."})} />
                            <ListRadioOptions
                                className={`d-inline-flex no-radius`}
                                items={[
                                    { text:intl.formatMessage({ id: "I have an e-ticket or boarding pass to upload"}), value: true },
                                ]}
                                onSelect={() => {
                                    setHasBoardingPass(!hasBoardingPass)
                                }}
                                value={hasBoardingPass}
                            />
                        </div>
                    </div>
                    { hasBoardingPass && <>
                    <div className="col-md-12 mx-md-0 mx-auto text-md-start text-center">
                        <div className="form-block">
                            {evidence && evidenceMime !== 'pdf' &&<><p style={{textAlign: 'left'}}><FormattedMessage id="Preview:"/></p>
                            <img src={evidence} alt="preview"className="image-preview"/>
                            <p>{evidenceName}</p></>}
                            <FileInput
                                value={evidence}
                                onChange={(file, mime, name) => {
                                    setEvidence(file)
                                    setEvidenceMime(mime)
                                    setEvidenceName(name)
                                    setEvidenceError(false)
                                }}
                            />
                            {evidenceError ?                                  
                                <div>
                                    <span className="text-danger">
                                        <FormattedMessage id="Please enter an evidence type"/>
                                    </span>
                                </div>: <div></div>}
                        </div>
                    </div>
                    </>}



                    <div className="col-md-6 text-center mx-auto">
                        <FormActionButton
                            onNext={() => {
                                if(hasBoardingPass && !evidence ){setEvidenceError(true)}else{setEvidenceError(false)}
                                if(!hasBoardingPass || (hasBoardingPass && evidence)){
                                    updateAction({
                                    evidence,
                                    evidenceMime,
                                    evidenceType: 'Boarding Pass',
                                    bookingReference,
                                }, (COMPENSATE_SCREEN.EVIDENCE + 1))}
                            }}
                            //onBack={() => { onBack(COMPENSATE_SCREEN.EVIDENCE - 1) }}
                        />
                        <br/>
                        <img src={`/img/light-bulb.svg`} className="light-bulb" alt="Light bulb"/><b className='fst-italic'><FormattedMessage id="EVIDENCE encouragement text"/></b>
                    </div>
                </div>
            </div>


        </>
    )
}