import { Form } from "react-bootstrap";

export default function TextArea({ label, placeholder, rows = 3, value, onChange, style }) {
    return (
        <>
            <Form.Label>{label}</Form.Label >
            <Form.Control
                as="textarea"
                rows={rows}
                value={value}
                onChange={onChange}
                placeholder={placeholder}
                style={style}
                maxLength={1000}
            />
        </>
    )
}