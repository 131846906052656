import { useState, useRef } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { COMPENSATE_SCREEN, GET_VALID_CLAIM_DATA } from "../../../constants";
import FormActionButton from "../../shared/FormActionButton";
import ListFlightSearch from "../../shared/ListFlightSearch";
import axios from "axios";
import Popup from "reactjs-popup"
import { Button } from "react-bootstrap";
import Button_ from "../Button";
import Label from "../Label";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'





export default function FlightList({ compensate, onNext, onBack, nextPage, previousPage,
     directFlight, affectedFlight, lastLeg, replacementFlight, isSubmitted, departure, destination }) {

    console.log(compensate)
    const scrollRef = useRef(null);
    const executeScroll = () => scrollRef.current.scrollIntoView() 
    const intl = useIntl();
    const [flight, setFlight] = useState(() => {
        if(replacementFlight){
            return compensate.replacement
        } else if (lastLeg){
            return compensate.last
        } else{
            return compensate.flight
        }
    })
    const [openModal, setOpenModal] = useState(false)
    const [flightsList, setFlightsList] = useState(compensate.flightsList ?? []);
    const [flightsRecords, setflightsRecords] = useState(() => {
        if (replacementFlight){
            return compensate.replacementFlightRecords ?? compensate.flightsRecords
        } else if(lastLeg){
            return compensate.lastLegRecords ?? compensate.flightsRecords
        } else if(affectedFlight) {
            return compensate.affectedFlightRecords ?? compensate.flightsRecords
        } else{
            return compensate.flightsRecords ?? []
        }
    });
    const departureAirport = departure
    const destinationAirport = destination
    const validClaimCheck = (replacementFlight) =>{
        if(replacementFlight){
            return  axios.post(GET_VALID_CLAIM_DATA(), {
                "initial_departure_airport_code": compensate?.departureAirport?.value,
                "operating_airline_name_of_flight_that_went_wrong": compensate?.flightData?.operatingAirlineName,
                "final_destintion_flightid": compensate?.lastLeg?.flightId,
                "final_destination_airline": compensate?.lastLeg?.codeshare?.marketingAirlineName ?? compensate?.lastLeg?.operatingAirlineName,
                "replacement_flightid" : flightsRecords?.find(data => data.flightLabel === flight).flightId,
                "replacement_airline": flightsRecords?.find(data => data.flightLabel === flight)?.codeshare?.marketingAirlineName 
                    ?? flightsRecords?.find(data => data.flightLabel === flight).operatingAirlineName
            })
        }else{
            return  axios.post(GET_VALID_CLAIM_DATA(), {
                "initial_departure_airport_code": compensate?.departureAirport?.value,
                "operating_airline_name_of_flight_that_went_wrong": compensate?.flightData?.operatingAirlineName 
                ?? flightsRecords?.find(data => data.flightLabel === flight).operatingAirlineName,
                "final_destintion_flightid": flightsRecords?.find(data => data.flightLabel === flight).flightId,
                "final_destination_airline":  flightsRecords?.find(data => data.flightLabel === flight)?.codeshare?.marketingAirlineName 
                ?? flightsRecords?.find(data => data.flightLabel === flight).operatingAirlineName
            })
        }
    }

    const [filghtError, setflightError] = useState(false); 
    const items = flightsRecords.map((flight, index) => ({
        value: flight.flightLabel, // Or any other unique identifier
        flightLabel: flight.flightLabel,
        flightNumber: flight.codeshare.marketingAirlineNumber ?? flight.flightNumber,
    }));

    function isObjectEmpty(obj) {
        return Object.keys(obj).length === 0;
      }

    return (
        <>
        <Popup open={openModal === true} className='undo-counter' onClose={() => setOpenModal(false)} modal> 
                {close => (
                    <div>
                        <img src="/uplers-icons/icon5.svg" style={{height: '100px'}} /> 
                        <br />
                        <br />
                        {intl.formatMessage({
                            id:"The flight you selected as the replacement flight is incorrect, as it flew prior to your original scheduled flight."
                        })}
                        <Button_ 
                            label={'OK'}
                            onClick={() => {setOpenModal(false); close();}}
                            style={{backgroundColor: '#198754'}}
                        />
                    </div>
            ) }
            </Popup>

            <div className="heading fdc-form d-flex justify-content-center align-items-center uplers-icon">
                <img src="/uplers-icons/icon1.svg" alt="icon"/> 
                {directFlight && <h2 className="title"><FormattedMessage id="Please select your flight from the list below" /></h2>}
                {affectedFlight && <h2 className="title">
                    <FormattedMessage 
                        id="Please select the flight that caused the disruption"
                        defaultMessage="Please select the flight that <strong>caused</strong> the disruption"
                        values={{
                            strong: (chunks) => <strong>{chunks}</strong>
                        }}/></h2>}
                {lastLeg && 
                    <h2 className="title">
                    <FormattedMessage 
                        id="Please select your connecting flight" 
                        defaultMessage="Please select your <strong>connecting</strong> flight"
                        values={{
                            strong: (chunks) => <strong>{chunks}</strong>
                        }} />
                        </h2>}
                {replacementFlight && <h2 className="title">
                    <FormattedMessage 
                        id="Please select your replacement flight details" 
                        defaultMessage="Please select your <strong>replacement</strong> flight details"
                        values={{
                            strong: (chunks) => <strong>{chunks}</strong>
                        }} />
                    </h2>}
            </div>
            <div className="fdc-form gif-input">
                <h3 className="text-center text-uppercase text-danger title flight-list-heading"><b>{departureAirport?.label.split(",")[1]}</b> <img src="/img/plane-with-arrow.svg" className="flight-list-icon" alt="icon"/> <b>{destinationAirport?.label.split(",")[1]}</b></h3>
                <FormattedMessage id="If your flight is not displayed, was your flight on the next day? Go back to change the date." />
                <ListFlightSearch
                    className='radio-button w-100 d-inline-flex'
                    items={[...items]}
                    onSelect={(flight) => {setFlight(flight); executeScroll()}}
                    value={flight}
                    compensate={compensate}
                    lastLeg={lastLeg}
                    replacementFlight={replacementFlight}
                />
                {filghtError && flight === undefined?
                    <div>   
                        <span className="text-danger">
                            <FormattedMessage id="Please select the flight"/>
                        </span>
                    </div>
                    :<></>
                    }
            </div>

            <FormActionButton
                onNext={() => {
                    console.log(flight)
                    if(flight === '' || flight === undefined){ setflightError(true) }

                    if (flight) {
                        isSubmitted(true)
                        validClaimCheck(replacementFlight).then((res) => {
                            console.log(res.data.message.data)
                            isSubmitted(false)
                            if(res.data.message.data.delay < 0){
                                setOpenModal(true)
                            }else{
                                onNext({
                                flight: (affectedFlight || directFlight) ? flight : compensate.flight,
                                last: lastLeg ? flight : compensate.last,
                                replacement: replacementFlight ? flight : compensate.replacement,
                                flightData: (affectedFlight || directFlight) ? res.data.message.data : compensate?.flightData, 
                                lastLeg: lastLeg ? res.data.message.data : compensate?.lastLeg, 
                                replacementFlight: replacementFlight ? res.data.message.data : compensate?.replacementFlight
                            }, (nextPage(res.data.message.data.validClaim,res.data.message.data.jurisdiction)))}
                        }).catch((err) => {
                            console.log(err)
                            isSubmitted(false)
                        })
                    }
                }}
                onBack={() => {
                    onBack(previousPage())
                }}
            />
            <div ref={scrollRef}></div>
            <br/>
            <div className="text-center">
                {directFlight && <> <img src={`/img/light-bulb.svg`} className="light-bulb" alt="Light bulb"/><b className='fst-italic'><FormattedMessage id="DIRECT_FLIGHT_SEARCH encouragement text"/></b></>}
                {affectedFlight && <> <img src={`/img/light-bulb.svg`} className="light-bulb" alt="Light bulb"/><b className='fst-italic'><FormattedMessage id="AFFECTED_FLIGHT_SEARCH encouragement text"/></b></>}
                {lastLeg && <><img src={`/img/light-bulb.svg`} className="light-bulb" alt="Light bulb"/><b className='fst-italic'><FormattedMessage id="LAST_LEG_FLIGHT_SEARCH encouragement text"/></b></>}
                {replacementFlight && <><img src={`/img/light-bulb.svg`} className="light-bulb" alt="Light bulb"/><b className='fst-italic'><FormattedMessage id="REPLACEMENT_FLIGHT_SEARCH encouragement text"/></b></>}
            </div>
        </>
    )
}