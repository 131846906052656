import React, { useRef } from "react";
import { Link } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import Dropzone from "./Dropzone";
import SignatureCanvas from 'react-signature-canvas'

const YourConfirmation = () => {
    const signPad = useRef(null);
    return (
        <div className="fdc-wrapp">
            <Header bg='#ffffff' />
            <div className="gb gap position-relative">
                <div className="container">
                    <div className="fdc-form-wrapp">
                        <div className="fdc-stepper-wrapp">
                            <div className="stepper-block active filled-after">
                                <div className="sb-image-block">
                                    <img src="img/f-stepper-1.png" alt="" />
                                </div>
                                <div className="fdc-stpper-title fw-semibold theme-color-1">Enter your details</div>
                            </div>
                            <div className="stepper-block active filled-before filled-after">
                                <div className="sb-image-block">
                                    <img src="img/f-stepper-2.png" alt="" />
                                </div>
                                <div className="fdc-stpper-title fw-semibold theme-color-1">Complete your details</div>
                            </div>
                            <div className="stepper-block active filled-before">
                                <div className="sb-image-block">
                                    <img src="img/f-stepper-3.png" alt="" />
                                </div>
                                <div className="fdc-stpper-title fw-semibold theme-color-1">Your Confirmation</div>
                            </div>
                        </div>
                        <div className="fdc-shadow-block">
                            <div className="fdc-title-block text-center">
                                <h2 className="title">Lets Finish</h2>
                            </div>
                            <div className="fdc-form gif-input">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-block">
                                            <label>Please add your booking reference confirmation</label>
                                            <Dropzone />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-block">
                                            <label>Import from url</label>
                                            <div className="input-filed with-btn d-flex">
                                                <input type="text" placeholder="Add file url" />
                                                <button className="btn-w color1">Upload</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-block">
                                            <label>Fill Signature</label>
                                            <div className="input-filed with-btn d-flex">
                                                <SignatureCanvas penColor='green'
                                                    canvasProps={{ className: 'sig-canvas' }} ref={signPad} />
                                            </div>
                                            <div className="text-end mt-2">
                                                <button className="btn-w gray" onClick={() => signPad.current.clear()}>Clear</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 gap-top">
                                        <div className="form-block">
                                            <div className="checkbox">
                                                <input type="checkbox" id="html" />
                                                <label for="html">By signing above, I confirm that I have read and accept both the Air Travel Claim and My Law Matters terms and conditions. I consent for my signature to be applied to all necessary documentation in the pursuit of the claim on behalf of myself and any other passengers in my party. I agree to Air Travel Claim to investigate my claim and, if it is deemed valid, I consent for the claim to be transferred to My Law Matters.</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 mx-auto">
                                        <div className="row gx-2">
                                            <div className="col-md-6 text-center mx-auto mt-4">
                                                <Link to="/complete-detail" className="btn-w black w-100">Back</Link>
                                            </div>
                                            <div className="col-md-6 text-center mx-auto mt-4">
                                                <Link to="/congratulations" className="btn-w color3 w-100">Submit</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="circle md orange left center"></div>
            <div className="circle md blue right"></div>
            <Footer />
        </div>
    )
}

export default YourConfirmation;