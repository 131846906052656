import React from 'react'
import FlightList from '../shared/FlightList/FlightList'
import { COMPENSATE_SCREEN } from '../../constants'

export default function DirectFlightSearch({ compensate, onNext, onBack, isSubmitted }) {

  const nextPage = (validClaim) =>{
    return validClaim ? COMPENSATE_SCREEN.DELAY_REASON : COMPENSATE_SCREEN.No_CLAIM
  }

  const previousPage = () =>{
    return compensate.isSourceAffiliate ? COMPENSATE_SCREEN.MOBILE_VALIDATION : COMPENSATE_SCREEN.DIRECT_FLIGHT_DATE
  }

  return (
    <FlightList 
      onBack={onBack}
      onNext={onNext}
      compensate={compensate} 
      nextPage={nextPage}
      previousPage={previousPage}
      departure={compensate.departureAirport}
      destination={compensate.destinationAirport}
      directFlight
      isSubmitted={isSubmitted}
    />
  )
}
