import React from 'react'
import FlightList from '../shared/FlightList/FlightList'
import { COMPENSATE_SCREEN } from '../../constants'

export default function AffectedFlightSearch({ compensate, onNext, onBack, isSubmitted }) {

  const retNumber =  compensate.affectedFlights?.match(/\d+/)[0]
  const affectedFlightss = retNumber > 0 ? `Flight ${retNumber}`: '';

  const nextPage = (validClaim) =>{
    return compensate.affectedFlightss !== `Flight ${compensate.connectingFlights.length}` 
      ? COMPENSATE_SCREEN.LAST_LEG_FLIGHT_DATE : validClaim 
        ? COMPENSATE_SCREEN.DELAY_REASON : COMPENSATE_SCREEN.No_CLAIM
  }

  const previousPage = () =>{
    return COMPENSATE_SCREEN.CONNECTING_FLIGHTS
  }

  return (
    <FlightList 
      onBack={onBack}
      onNext={onNext}
      compensate={compensate} 
      nextPage={nextPage}
      previousPage={previousPage}
      departure={compensate?.connectingFlights[retNumber-1]?.departureAirport }
      destination={compensate?.connectingFlights[retNumber-1]?.destinationAirport}
      affectedFlight
      isSubmitted={isSubmitted}
    />
  )
}
