import { FormattedMessage } from "react-intl"
import { getErrorCompensationPayload } from "../../containers/FlightCompensation/mapper.js"
import { COMPENSATION_SUBMIT_ENDPOINT } from "../../constants"
import axios from 'axios'
import { useEffect } from "react"

export default function Error({ compensate }) {

  useEffect(()=>{
     axios.post(COMPENSATION_SUBMIT_ENDPOINT, getErrorCompensationPayload(compensate)).then((res) => 
    {
      console.log(res.data)
    })
  },[])

  return (
    <>

<div className="heading">
        <h2 className="title"><FormattedMessage id="Unfortunately we've encountered a problem." /></h2>
      </div>

      <div className="fdc-form gif-input">
        <div className="box-message fw-semibold theme-color-1 text-center">
          <FormattedMessage id="We're sorry, please contact our Customer Services on 0330 808 8636." />
        </div>
      </div>

      <br></br>

          {compensate.error ?
                       <div className="fdc-form gif-input">
                       <span><FormattedMessage id="Error Message:"/></span>
                       <div className="box-message fw-semibold theme-color-1 text-center"
                       >
                         <h6> {compensate.error} </h6>
                       </div>
                     </div>
            :

            <></>
          }



    </>
  )
}