import React from "react";
import { useDropzone } from 'react-dropzone'

function Dropzone({ open }) {
    const { getRootProps, getInputProps, acceptedFiles } =
    useDropzone({});

    const files = acceptedFiles.map((file) => (
        <li key={file.path}>
            {file.path} - {file.size} bytes
        </li>
    ));
    return (
        <div className="dd-wrapp">
            <div {...getRootProps({ className: "dropzone" })}>
                <input className="input-zone" {...getInputProps()} />
                <div className="dd-block text-center">
                    <img src="img/dd.png" alt="" className="dd-image-block" />
                    <div className="dropzone-content">
                        Drag & Drop or <span className="theme-color-1 cp">Choose file</span> to Upload
                    </div>
                </div>
            </div>
            <div className="dd-files text-center mt-4">
                <ul className="mb-0">{files}</ul>
            </div>
        </div>
    );
}

export default Dropzone;