import React from 'react'
import DepartureDate from '../shared/DepartureDate.js/DepartureDate'
import { COMPENSATE_SCREEN } from '../../constants'

export default function AffectedFlightDate({ compensate, onNext, onBack, isSubmitted, areDetailsSubmitted, setAreDetailsSubmitted}) {

  const retNumber =  compensate.affectedFlights?.match(/\d+/)[0]
  const affectedFlightss = retNumber > 0 ? `Flight ${retNumber}`: '';

  const nextPage = (FlightList) => {
      if(FlightList.length > 0){
          return COMPENSATE_SCREEN.AFFECTED_FLIGHT_SEARCH
      } else {
          return COMPENSATE_SCREEN.AFFECTED_FLIGHT_DATE
      }

  }

  const previousPage = () => {
      return COMPENSATE_SCREEN.CONNECTING_FLIGHTS
  }

  return (
    <DepartureDate 
      onBack={onBack}
      onNext={onNext}
      compensate={compensate}
      isSubmitted={isSubmitted}
      areDetailsSubmitted={areDetailsSubmitted}
      setAreDetailsSubmitted={setAreDetailsSubmitted}
      departure={compensate?.connectingFlights[retNumber-1]?.departureAirport }
      destination={compensate?.connectingFlights[retNumber-1]?.destinationAirport}
      affectedFlightss={affectedFlightss}
      nextPage={nextPage}
      previousPage={previousPage}
      affectedFlight
    />
  )
}
