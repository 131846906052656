import moment from 'moment/moment';

function clientObject (compensation){
  const clients = [
    {
      title: compensation.title,
      firstname: compensation.firstName.trim(),
      lastname: compensation.surname.trim(),
      testing: (process.env.REACT_APP_TESTCASES || compensation.emailAddress.includes('@tanantech.co.uk') ) ? true : false,
      
      
      dob: moment(compensation.dob).format('DD/MM/YYYY'),
      email: {
        primary: compensation.emailAddress.trim()
      },
      phone: {
        primary: compensation.mobileNumber,
        secondary: compensation.landline
      },
      address: {
        line1: compensation.address1,
        line2: compensation.addressLine2,
        city: compensation.city,
        postcode: compensation.postcode,
        county: compensation.county,
        country: compensation.country
      },
      clientdata: {
        clientSignature: compensation.signature?.split('base64,')?.[1],
        whatsappOptIn: compensation.whatsappOptIn,
      }
    }
  ];

  if (compensation.clients?.length) {
    compensation.clients.forEach((client) => {
      clients.push({
        firstname: client.firstname.trim(),
        lastname: client.lastname.trim(),
        email: {
          primary: client.email.trim()
        },
        phone: {
          primary: client.mobile
        },
        clientdata: {
          agedUnder18: client.under18
        }
      });
    });
  }
  return clients;
}

function caseDataObject(compensation){
  const caseData = {

    brand: compensation?.flightData?.jurisdiction === 'UK 261'? 'My Law Matters' :'Air Travel Claim',
    departureAirport: compensation.departureAirport?.label.slice(0, compensation.departureAirport.label.lastIndexOf(',')),
    marketingChannel: compensation.marketingChannel ? compensation.marketingChannel.toLowerCase() : 'organic',
    marketingCampaignName: compensation.marketingCampaignName ? compensation.marketingCampaignName.toLowerCase() : null,
    marketingCreative: compensation.marketingCreative ? compensation.marketingCreative.toLowerCase() : null,
    marketingAudience: compensation.marketingAudience ? compensation.marketingAudience.toLowerCase() : null,    
    vatApplicable: compensation.ukResident ? true : false,
    referAFriendCaseReference: compensation.caseReference,
    utmMedium: compensation.utmMedium,
    utmSource: compensation.utmSource, 
    utmCampaign: compensation.utmCampaign, 
    utmCampaignId: compensation.utmCampaignId,
    utmDevice: compensation.utmDevice,  
    utmHeadline: compensation.utmHeadline,  
    utmTerm: compensation.utmTerm,  
    utmTermId: compensation.utmTermId,  
    utmAdsetId: compensation.utmAdsetId,  
    utmGclid: compensation.utmGclid,  
    utmExtension: compensation.utmExtension ,  
    utmContent: compensation.utmContent,

    departureAirportCode: compensation.departureAirport?.value,
    arrivalAirport: compensation.destinationAirport?.label.slice(0, compensation.destinationAirport.label.lastIndexOf(',')),
    arrivalAirportCode: compensation.destinationAirport?.value,
    connectingFlight: compensation.connectingFlight,
    operatingAirline: compensation?.flightData?.operatingAirlineName,
    marketingAirline: compensation?.flightData?.codeshare?.marketingAirlineName,
    scheduledDateOfDeparture: moment(compensation?.flightData?.scheduledDepartureDate).format('DD/MM/YYYY'),
    scheduledDepartureTime: moment(compensation?.flightData?.scheduledDepartureDate).format('HH:mm:ss'),
    scheduledDateOfArrival: moment(compensation?.flightData?.scheduledArrivalDate).format('DD/MM/YYYY'),
    scheduledArrivalTime: moment(compensation?.flightData?.scheduledArrivalDate).format('HH:mm:ss'),
    flightThatWentWrongUniqueFlightIdentifier: compensation?.flightData?.flightId,
    flightNumber: compensation?.flightData?.codeshare?.marketingAirlineNumber ?? compensation?.flightData?.flightNumber,
    flightThatWentWrongCancellationStatus: compensation?.flightData?.flightCancelled,
    totalMinutesDelayedBy: compensation?.replacementFlight ? compensation?.replacementFlight?.delay?.toString() : compensation?.flightData?.delay?.toString(),
    doNotProceedToLegalTrack: compensation?.replacementFlight?.doNotProceedToLegalTrack === true 
      ? 'Delay Under 3 Hours'
      : !compensation?.replacementFlight && compensation?.flightData?.doNotProceedToLegalTrack === true
      ? 'Delay Under 3 Hours' : null,



    

    flightClaimReason: compensation.delayReason3,
    bookingReference: compensation?.bookingReference,
     tradingStyle: window.location.href.includes('airdelayed.com') 
      ? 'Air Delayed'
      : window.location.href.includes('flightclaims.co.uk')
        ? 'Flight Claims'
        : 'Air Travel Claim',

    websiteVersion: '1.10.0', //new URL(window.location.href).searchParams.get('version'),

    // alternateFlightNumber: compensation.alternativeFlightNumber,
    offeredAnAlternateFlight: compensation.wereOfferedAlternativeFlight,
    // clientsAdditionalInformation: compensation.comment,
    numberOfPassengersOnTheBooking : compensation.passengers

  };

  if (compensation.clients?.length >= 1) {
    let numberOFClients = compensation.clients.length 
    numberOFClients++// Remove the extra question mark
    caseData.administrationFee = 15 * numberOFClients;
  }else{
    caseData.administrationFee = 15
  }

  if (compensation.delayReason3 === 'Delayed') {
    caseData.howLateWasYourFlight = compensation.howManyHoursLate
    caseData.airlineReasonForDelay = compensation.causedTheDelay
    caseData.airlineReasonForDelayOther = compensation.airlineReasonForDelayOther
  } else if (compensation.delayReason3 === 'Cancelled') {
    caseData.cancellationNotice = compensation.whenInformedFlightCancelled
    caseData.airlineCauseForCancellation = compensation.causedTheDelay
    caseData.airlineCancelOther = compensation.airlineReasonForDelayOther
  } else if (compensation.delayReason3 === 'Denied Boarding') {
    caseData.checkInPresentForBoarding = compensation.checkInForBoarding === 'Yes' ? true : false;
    caseData.deniedBoardingReason = compensation.causedTheDelay
    caseData.deniedBoardingReasonOther = compensation.airlineReasonForDelayOther
  } else if (compensation.delayReason3 === 'Other') {
    caseData.flightClaimReasonOther = compensation.flightClaimReasonOther
  }

  if(!compensation?.replacementFlight && !compensation.lastLeg){
    caseData.quantumClaimValue =  compensation?.flightData?.claimValue ? compensation?.flightData?.claimValue * (compensation.clients?.length + 1) : 0
    caseData.lengthOfFlightBandings = compensation?.flightData?.lengthOfFlightBandings
    caseData.jurisdictionOfCase = compensation?.flightData?.jurisdiction
    caseData.dualJurisdiction = compensation?.flightData?.dualJurisdiction
    caseData.individualClaimValue = compensation?.flightData?.claimValue ? compensation?.flightData?.claimValue : 0
  }

  if (compensation.connectingFlight) {
    caseData.numberOfFlights = compensation.numberOfFlights;
    compensation.connectingFlights?.forEach((flight, i) => {
      i = i+1
      caseData[`arrivalAirportFlight${i}`] = flight?.destinationAirport?.label.slice(0, flight?.destinationAirport?.label.lastIndexOf(',')) + ' (' + flight?.destinationAirport?.value + ')'
      caseData[`departureAirportFlight${i}`] = flight?.departureAirport?.label.slice(0, flight?.departureAirport?.label.lastIndexOf(','))  + ' (' + flight?.departureAirport?.value + ')'
    })
    caseData.flightThatWentWrongDepartureAirport = compensation?.flightData?.departureAirport
    caseData.flightThatWentWrongArrivalAirport = compensation?.flightData?.arrivalAirport
    caseData.missedConnection = compensation.isMissedConnection
    caseData.didYouFlyOnAReplacementFlight = compensation.hasBoardedReplacement
    caseData.didYouTravelToADifferentEndDestinationAirport = compensation.isDestinationSame
  }
  if(compensation.lastLeg){
    caseData.lastLegFlightNumber = !compensation?.lastLeg?.codeshare ? compensation?.lastLeg?.flightNumber : compensation?.lastLeg?.codeshare?.marketingAirlineNumber
    caseData.lastLegDepartureAirport = compensation?.lastLeg?.departureAirport
    caseData.lastLegArrivalAirport = compensation?.lastLeg?.arrivalAirport
    caseData.lastLegScheduledDepartureTime = moment(compensation?.lastLeg?.scheduledDepartureDate).format('HH:mm:ss')
    caseData.lastLegScheduledDateOfDeparture = moment(compensation?.lastLeg?.scheduledDepartureDate).format('DD/MM/YYYY')
    caseData.lastLegScheduledArrivalTime = moment(compensation?.lastLeg?.scheduledArrivalDate).format('HH:mm:ss')
    caseData.lastLegScheduledDateOfArrival = moment(compensation?.lastLeg?.scheduledArrivalDate).format('DD/MM/YYYY')
    caseData.lastLegOperatingAirline = compensation?.lastLeg?.operatingAirlineName
    caseData.lastLegMarketingAirline = compensation?.lastLeg?.codeshare?.marketingairlineName
    caseData.lastLegFlightUniqueFlightIdentifier = compensation?.lastLeg?.flightId
      if(!compensation.replacementFlight){
        caseData.quantumClaimValue = compensation?.lastLeg?.claimValue ? compensation?.lastLeg?.claimValue * (compensation.clients?.length + 1) : 0
        caseData.lengthOfFlightBandings = compensation?.lastLeg?.lengthOfFlightBandings
        caseData.jurisdictionOfCase = compensation?.lastLeg?.jurisdiction
        caseData.dualJurisdiction = compensation?.lastLeg?.dualJurisdiction
        caseData.individualClaimValue = compensation?.lastLeg?.claimValue
      }
  }
  if(compensation.replacementFlight){
    caseData.replacementFlightDepartureAirport = compensation?.replacementFlight?.departureAirport
    caseData.replacementFlightArrivalAirport = compensation?.replacementFlight?.arrivalAirport
   // caseData.replacementFlightActualArrivalTime = moment(compensation?.replacementFlight?.replacementArrivalDate).format('HH:mm:ss')
   // caseData.replacementFlightActualArrivalDate = moment(compensation?.replacementFlight?.replacementArrivalDate).format('DD/MM/YYYY')
    caseData.replacementFlightScheduledDateOfDeparture = compensation?.replacementFlight?.replacementScheduledDepartureDate
    caseData.replacementFlightScheduledDepartureTime = compensation?.replacementFlight?.replacementScheduledDepartureTime
    caseData.replacementFlightScheduledArrivalTime =  compensation?.replacementFlight?.replacementScheduledArrivalDate
    caseData.replacementFlightScheduledArrivalTime = compensation?.replacementFlight?.replacementScheduledArrivalTime
    caseData.replacementFlightUniqueFlightIdentifier = compensation?.replacementFlight?.flightId
    caseData.quantumClaimValue = compensation?.replacementFlight?.claimValue ? compensation?.replacementFlight?.claimValue * (compensation.clients?.length + 1) : 0
    caseData.lengthOfFlightBandings = compensation?.replacementFlight?.lengthOfFlightBandings
    caseData.jurisdictionOfCase = compensation?.replacementFlight?.jurisdiction
    caseData.dualJurisdiction = compensation?.replacementFlight?.dualJurisdiction
    caseData.individualClaimValue = compensation?.replacementFlight?.claimValue
  }
  if (compensation.evidenceType === 'Boarding Pass') {
    caseData.boardingPass =  {
        filename: `boarding-pass.${compensation.evidenceMime}`,
        value: compensation.evidence?.split('base64,')?.[1]
      }
    } else if (compensation.evidenceType === 'e-ticket') { 
     caseData.flightTicket = {
        filename: `e-ticket.${compensation.evidenceMime}`,
        value: compensation.evidence?.split('base64,')?.[1]
      }
    }

  return caseData;
}


export function getCompensationPayload(compensation) {
  const clients = clientObject(compensation)
  const caseData = caseDataObject(compensation)
  return {
    web_form_id: 'flightdelays_milestone1',
    clients: clients,
    state: compensation.emailAddress.includes('@tanantech.co.uk') ? 'Case Rejected'
          : new URL(window.location.href).searchParams.get('utm_medium') === 'Contact Centre'
            ?'Incomplete Claim':
            compensation.connectingFlight || (compensation.flightData && Object.keys(compensation.flightData).length === 0) || compensation.flightData === null 
            ? 'Incomplete Validation'   
            :'Claim Received',
    defendant: compensation.flightData?.operatingAirlineName,
    casedata: caseData,
    boardingpass: compensation.evidence,
    boardingpassfiletype: compensation.evidenceMime,
    comments: compensation.comment,
    error: compensation.error
  };
}

export function getErrorCompensationPayload(compensation) {
  const clients = clientObject(compensation)
  const caseData = caseDataObject(compensation)

 const testing = (process.env.REACT_APP_TESTCASES || compensation.emailAddress.includes('@tanantech.co.uk') )
                  ? true
                  : false
  return {
    web_form_id: 'flightdelay_error',
    clients: clients,
    state: 'Incomplete Validation',
    defendant: compensation.flightData?.operatingAirlineName,
    casedata: caseData,
    boardingpass: compensation.evidence,
    boardingpassfiletype: compensation.evidenceMime,
    comments: compensation.comment,
    error: compensation.error,
    testing: testing
  };
}

export function getCompensationPayloadForCRM(compensation) {
  const clients = clientObject(compensation)
  const caseData = caseDataObject(compensation)
  const testing = (process.env.REACT_APP_TESTCASES)
                  ? true
                  : false
  return {
    payload:{
    clients: clients,
    type: 'Flight Delay',
    campaignCode: 'ATCWEBSITE',
    state: compensation.emailAddress.includes('@tanantech.co.uk') ? 'Case Rejected'
        : new URL(window.location.href).searchParams.get('utm_medium') === 'Contact Centre'
              ?'Incomplete Claim'
              :'Claim Received',
    defendant: compensation.flightData?.operatingAirlineName,
    casedata: caseData,
    testing: testing,
     }
  };

}

export function getCompensationPageView(compensation,currentPageNumber,currentPage,sessionId) {
  const caseData = caseDataObject(compensation)
  return{
    web_form_id: "atc_page_view",
    websiteVersion: caseData.websiteVersion,
    pageWithNumber: currentPageNumber,
    page: currentPage,
    guid: sessionId
  }
}

export function getCompensationUpdatePayloadForCRM(data) {
  if (data.evidenceType === 'Boarding Pass') {
    return {
      payload: {
      "eTicketOrBoardingPass": {
        filename: `boarding-pass-or-e-ticket.${data.evidenceMime}`,
        value: data.evidence?.split('base64,')?.[1]
      },
      bookingReference: data.bookingReference
    }
  }  
  } else if(data.bookingReference && !data.evidenceType){
    return{
      payload: {
        bookingReference: data.bookingReference
      }
    }
  }else if (data.comment || data.paidForHotel || data.paidForTaxi || data.paidforFood) {
    return {
      payload:{
      clientsAdditionalInformation: data.comment,
      didTheClientIncurAdditionalHotelExpenses: data?.paidForHotel,
      didTheClientIncurAdditionalTaxiExpenses: data?.paidForTaxi,
      didTheClientIncurAdditionalFoodExpenses: data?.paidForFood
    }
  }
  }
  return {

  }
}
