import { Button } from "react-bootstrap";
import './Button.css'

export default function Button_({ onClick, label, style, className}) {

    return (
        <div className={`custom-button ${className}`}>
            <Button className="btn-w color3" onClick={onClick} style={style}>{label}</Button>
        </div>
    )
}