import { useEffect, useState } from "react";
import English from '../lang/en.json';
import French from '../lang/fr.json';
import { DEFAULT_LOCALE } from "../store/constants";

export default function useLocale(lang=DEFAULT_LOCALE) {
    const [locale, setLocale] = useState(lang);
    const [message, setMessage] = useState({});

    useEffect(() => {
        if(locale === 'fr'){
            setMessage(French);
        }else{
            setMessage(English);
        }
    }, [locale])
    
    return {
        locale, 
        setLocale, 
        message,
        lang
    }
}