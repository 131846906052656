import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import moment from 'moment'; // Assuming moment is still used for formatting
import './ListFlightSearch.css';

export default function ListFlightSearch({ label, items, value, onSelect, className, divStyle, compensate, lastLeg, replacementFlight }) {
    const [filteredItems, setFilteredItems] = useState(items);

    useEffect(() => {
        if (lastLeg && compensate.flightData && compensate.flightData.scheduledArrivalDate) {
            // Parse the full timestamp and convert to a moment object
            const scheduledArrivalTime = moment(compensate.flightData.scheduledArrivalDate);
            const updatedItems = items.filter(item => {
                const timePart = item.value.split("-")[0].trim().split(" ")[0];
                const secondTime = moment(compensate.lastLegDate + "T" + timePart + ":00" + ".000");
                return moment(secondTime).isAfter(scheduledArrivalTime); // Compare times
            });
            setFilteredItems(updatedItems);
        } else if(replacementFlight && compensate.lastLeg && compensate.lastLeg.scheduledDepartureDate){
            const scheduledDepartureTime = moment(compensate.lastLeg.scheduledDepartureDate);
            const updatedItems = items.filter(item => {
                const timePart = item.value.split("-")[0].trim().split(" ")[0];
                const firstTime = moment(compensate.replacementFlightDate + "T" + timePart + ":00" + ".000");
                return moment(firstTime).isAfter(scheduledDepartureTime); // Compare times
            });
            setFilteredItems(updatedItems);
        }else{
            setFilteredItems(items); // Fallback to original list if no date is set
        }
    }, [items, compensate, lastLeg, replacementFlight]); // Rerun when items or compensate changes
    const Item = ({ item }) => {
        return (
            <>
                <label className={`${className}`} htmlFor={`checkbox-${item.value}`}>
                    <input
                        type="checkbox"
                        value={item.value}
                        checked={item.value === value}
                        id={`checkbox-${item.value}`}
                        onChange={() => onSelect(item.value)}
                    />
                    <span className="checkmark"></span>
                    <div className='ms-2 d-flex flex-row w-100 justify-content-between'>
                        <div>{item.flightLabel}</div>
                        <div>{item.flightNumber}</div>
                    </div>
                </label>
            </>
        );
    };

    return (
        <div className={`list-flight-search ${divStyle}`} >
            {label && <Form.Label>{label}</Form.Label>}
            {filteredItems.map((item) => <Item key={item.value} item={item} />)}
        </div>
    );
}
