import { Form } from "react-bootstrap";
import './Text.css';

export default function Text({ label, placeholder, value, required, onChange, style, help, id }) {
    return (
        <>
            {label && <Form.Label htmlFor={id} title={help ?? ""} style={help ? { cursor: 'help' } : {}}>{label}</Form.Label>}
            <Form.Control
                // className="text-input"
                id={id}
                value={value}
                onChange={onChange}
                placeholder={placeholder}
                style={style}
                type="text"
                required={required}
            />
        </>
    )
}