import Footer from '../components/webiste-components/Footer';
import Header from '../components/webiste-components/Header';
import './PageLayout.css';

export default function PageLayout(props) {
    return (<>
    <Header bg='#ffffff' />
        <div className="fdc-wrapp flex-grow-1">
            
            <div className=" gap position-relative ">
                {props.children}
            </div>            
        </div>
        <Footer />
   </> )
}