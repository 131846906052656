import { useEffect, useRef, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useIntl } from "react-intl";
import './FileInput.css';
import Button_ from "../Button";

export default function FileInput({ label, placeholder, value, onChange, style }) {
    const intl = useIntl()
    const [width, setWidth] = useState(window.innerWidth);
    const [errorMsg, setErrorMsg] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);

    const handleWindowSizeChange = () => {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width <= 768;

    const inputFile = useRef(null)
    const mobileInputFile = useRef(null)


    return (
        <>
            {label && <Form.Label className="label">{label}</Form.Label>}

                <Button_
                    onClick={() => {
                        inputFile.current.click()
                    }}
                    label={intl.formatMessage({ id: "Choose File" })}
                    style={{backgroundColor: "#001a42"}}
                />
                <input
                    ref={inputFile} type="file" id="imgupload" style={{ display: 'none' }} accept="image/*,application/pdf"
                    onChange={(e) => {
                        const file = e.target.files[0];
                        if (file) {
                            const MAX_FILE_SIZE = 51200 // 50MB
                            const fileSizeKiloBytes = file.size / 1024

                            if (fileSizeKiloBytes > MAX_FILE_SIZE) {
                                setErrorMsg("File size is greater than maximum limit");
                                setIsSuccess(false)
                                return
                            }


                            const filename = file.name
                            const fileType = file.type.split("/").pop()
                            const reader = new FileReader();
                            reader.onloadend = () => {
                                const base64String = reader.result
                                // .replace('data:', '')
                                // .replace(/^.+,/, '');
                                onChange(base64String, fileType, filename);
                            };
                            reader.readAsDataURL(file);
                            setErrorMsg("")
                            setIsSuccess(true)
                        } else {
                            onChange("", "")
                            setErrorMsg("")
                            setIsSuccess(true)
                        }

                    }}
                />
                {
                    isMobile && <>
                    <Button_
                        label={intl.formatMessage({ id: "Take Photo" })}
                        onClick={() => {
                            mobileInputFile.current.click()
                        }}
                        
                    />
                    <input id="photo-upload" 
                    ref={mobileInputFile}
                    style={{display: "none"}}
                    type="file" 
                    name="file" 
                    accept=".png, .jpg, .jpeg, .webp"
                    capture="environment"
                    onChange={(e) => { const file = e.target.files[0];
                        if (file) {
                            const MAX_FILE_SIZE = 51200 // 50MB
                            const fileSizeKiloBytes = file.size / 1024

                            if (fileSizeKiloBytes > MAX_FILE_SIZE) {
                                setErrorMsg("File size is greater than maximum limit");
                                setIsSuccess(false)
                                return
                            }


                            const filename = file.name
                            const fileType = file.type.split("/").pop()
                            const reader = new FileReader();
                            reader.onloadend = () => {
                                const base64String = reader.result
                                // .replace('data:', '')
                                // .replace(/^.+,/, '');
                                onChange(base64String, fileType, filename);
                            };
                            reader.readAsDataURL(file);
                            setErrorMsg("")
                            setIsSuccess(true)
                        } else {
                            onChange("", "")
                            setErrorMsg("")
                            setIsSuccess(false)
                        }}}/>
                </>}

                {/* <Form.Control
                    className="text-input"
                    value={value}
                    onChange={onChange}
                    placeholder={placeholder}
                    style={style}
                    type="text"
                /> */}
            <div className="space-between">
               {/* <p className="info-message">Max size: 50MB</p>
                <p>Allow Only JPEG, PNG, SVG format file</p>*/}
            </div>


            {isSuccess ? <p className="success-message">File Upload Successful</p> : null}
            <p className="error-message">{errorMsg}</p>
        </>
    )
}