import { Form } from 'react-bootstrap'
import './connectingFlightSelection.css'
import {FormattedMessage} from 'react-intl'

export default function ConnectingFlightSelection({ label, items, value, onSelect, className, divStyle }) {
    const Item = ({ item, i }) => {
        return (
            <>
                <label className={`${className}`} htmlFor={`Flight ${i+1}`}>
                    <input
                        type="checkbox"
                        value={`Flight ${i+1} (${item.departureAirport.value} - ${item.destinationAirport.value})`}
                        checked={`Flight ${i+1} (${item.departureAirport.value} - ${item.destinationAirport.value})` === value}
                        id={`Flight ${i+1}`}
                        onChange={() => {
                            onSelect(`Flight ${i+1} (${item.departureAirport.value} - ${item.destinationAirport.value})`)
                        }}
                    />
                    <span className="checkmark"></span>
                    <div className='ms-2 d-flex flex-row w-100 justify-content-between'>
                        {item.departureAirport?.label.split(',')[0]} - {item.destinationAirport?.label.split(',')[0]} 
                    </div>
                </label>
            </>
        )
    }

    return (
        <div className={`list-flight-search ${divStyle}`} >
            <div className="box-message fw-semibold theme-color-1">
                <FormattedMessage 
                id="Please select the flight that caused the disruption to your journey"
                defaultMessage="Please select the flight that <strong>caused</strong> the disruption to your journey"
                values={{
                    strong: (chunks) => <strong>{chunks}</strong>,
                  }}
                />
            </div>
            {items.map((item, i) => <Item key={i} item={item} i={i}/>)}
        </div>
    )
}