import { Form } from "react-bootstrap";
import './Select.css'

export default function Select({ label, placeholder, options = [], value, onChange, style, id }) {
    return (
        <div className="custom-dropdown">
            <Form.Label htmlFor={id} className="label">{label}</Form.Label>
            <div className="select-wrapper">
                <Form.Select
                    defaultValue={placeholder}
                    onChange={(e) => {
                        onChange(e.target.value)
                    }}
                    id={id}
                    style={style}
                >
                    {
                        options.map(item => <option key={item.value} value={item.value}>{item.text}</option>)
                    }
                </Form.Select>
            </div>
        </div>
    )
}