import { useState, useEffect } from 'react';
import { Form } from "react-bootstrap";
import { getMonth, getYear, getDaysInMonth } from 'date-fns'; // Add getDaysInMonth function
import range from 'lodash/range';
import './DatePicker.css'
import axios from 'axios';
import { GET_VALID_YEARS } from '../../../constants';

export default function DatePicker(props) {
    const { label, value, onChange, style, dateOfBirth, flightSearch, compensate } = props;

    const [day, setDay] = useState(() => {
      if(flightSearch){
        return compensate?.flightDay?.replace(/^0+/, '') ?? ''
      } else{
        return ''
      }
    });

    const [month, setMonth] = useState(() => {
      if(flightSearch && compensate.flightMonth){
        return (+compensate.flightMonth).toString() - 1 
      } else{
        return ''
      }
    });

    const [year, setYear] = useState(() => {
      if(flightSearch){
        return compensate.flightYear ?? ''
      } else{
        return ''
      }
    }); 
    const [validYears, setValidYears] = useState([])

    // Populate day options based on the selected month and year
    const daysInMonth = getDaysInMonth(new Date(year, month));
    const days = range(1, daysInMonth + 1);

    useEffect(() => {
      axios.get(GET_VALID_YEARS()
      ).then(data => setValidYears(data.data.message.data.valid_claim_years))
    },[])
  
    useEffect(() => {
      // Update the date in the state whenever any of the day, month, or year changes
      if (day && month !== '' && year !== '') {
        const formattedDate = formatDate(new Date(year, month, day));
        onChange(formattedDate); // Call the provided onChange function with the formatted date
      }
    }, [day, month, year, onChange]);
  
    const validateDob = () => {
      // Validation logic (if needed)
    };

    const currentYear = new Date().getFullYear();
    const years = dateOfBirth 
      ? range(currentYear - 17, 1900, -1) :
        flightSearch 
          ? [...validYears].reverse()
          : range(currentYear, 1900, -1);

    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
  
    const formatDate = (date) => {
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const year = date.getFullYear().toString();
      return `${year}-${month}-${day}`;
    };
    
  
    const handleDayChange = (e) => {
      setDay(parseInt(e.target.value));
    };
  
    const handleMonthChange = (e) => {
      setMonth(parseInt(e.target.value));
    };
  
    const handleYearChange = (e) => {
      setYear(parseInt(e.target.value));
    };

    
    return (
        <>
            <Form.Label >{label}</Form.Label>

            <div className="row g-0 d-flex justify-content-between">
            <div className="col-3">
              <select
              style={{border: (window.location.href.includes('LAST') || window.location.href.includes('REPLACEMENT')) && '2px solid #198754' }}
              name="day" id="day" value={day} onChange={handleDayChange}>
                <option defaultValue="-" hidden>
                  day
                </option>
                {days.map((dayNumber) => (
                  <option key={dayNumber} value={dayNumber}>
                    {dayNumber}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-4">
              <select name="month" id="month" value={month} onChange={handleMonthChange}>
                <option defaultValue="-" hidden>
                  month
                </option>
                {months.map((monthName, index) => (
                  <option key={index} value={index}>
                    {monthName}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-3">
              <select name="year" id="year" value={year} onChange={handleYearChange}>
                <option defaultValue="-" hidden>
                  year
                </option>
                {years.map((yearNumber) => (
                  <option key={yearNumber} value={yearNumber}>
                    {yearNumber}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </>
    )
}