import { useEffect, useRef, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { COMPENSATE_SCREEN, EACH_PASSENGER_CLAIM } from "../../constants";
import FormActionButton from "../shared/FormActionButton";
import ListRadioOptions from "../shared/ListRadioOptions";
import SignatureCanvas from 'react-signature-canvas'
import Label from "../shared/Label";
import Button_ from "../shared/Button";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Popup from "reactjs-popup";
import { Button } from "react-bootstrap";
import 'reactjs-popup/dist/index.css';
import MlmTerms from "../webiste-components/MlmTerms";
import AtcTerms from "../webiste-components/AtcTerms";
import Pricing from "../webiste-components/Pricing";



export default function Signature({ compensate, onNext, onBack, submitAction, isSubmitting }) {
    const [signature, setSignature] = useState(compensate.signature)
    const [termsConditions, setTermsConditions] = useState(compensate.termsConditions)
//    const [termsConditions2, setTermsConditions2] = useState(compensate.termsConditions2)
    const [signaturePadWidth, setSignaturePadWidth] = useState()
    const [clients, setClients] = useState(compensate.clients)
 //   const [otherClientSign, setOtherClientSign] = useState()
    const signatureRef = useRef();
  //  const signatureRsef = useRef([]);
    const intl = useIntl();
    const [signatureOverlay, setSignatureOverlay] = useState(true)
    const [signError, setsignError] = useState(false);
    const [termError, setTermError] = useState(false);
    const [undoCounter, setUndoCounter] = useState(0)
  
    const currentUrl = window.location.href;

    useEffect(() => {
        
        /*clients?.forEach((client, i) => {
            if (client.availableForSignature) {
                setOtherClientSign(i)
            }
        })*/

        const updateSignaturePadWidth = () => {
            setSignaturePadWidth(signatureRef?.current?._canvas?.parentElement?.clientWidth);
          };
      
          // Initially set the signature pad width
          updateSignaturePadWidth();
      
          // Attach the event listener for window resize
          window.addEventListener('resize', updateSignaturePadWidth);
      
          // Clean up the event listener when the component unmounts
          return () => {
            window.removeEventListener('resize', updateSignaturePadWidth);
          };

    
    }, [])

    const claimWorth = () =>{
         const numberOfClients = clients?.length+1 
         const totalClaimWorth = numberOfClients* EACH_PASSENGER_CLAIM;
        return  totalClaimWorth
    }

    const clearSig1 = () =>{
            signatureRef.current.clear();
            setSignature(false)
            setUndoCounter(undoCounter+1)
        }
    return (
        <>
            <div className="heading fdc-form d-sm-flex justify-content-center align-items-center uplers-icon"> 
                <img src="/uplers-icons/icon2.svg" alt="passenger-icon" className="d-block m-auto" /> 
                <h2 className="title"><FormattedMessage id="Please sign below to allow us to progress your claim." /></h2>
            </div>
            <div className="fdc-form gif-input">
                <div className="row">
                    <div className="col-md-12">
                        <div className="form-block">
                            <Popup className='undo-counter' open={undoCounter === 2} modal>
                                {close => (
                                    <div style={{ height: '200px', overflowY: 'auto', fontSize:'larger', fontWeight:'bold'}}>
                                    <Button label='X' style={{position: 'absolute', right:'0', top: '0', borderRadius: '0', backgroundColor: '#198754', padding: '2px 5px', fontSize: 'smaller'}} onClick={close}>
                                            X
                                        </Button>
                                        <br />
                                        <br />
                                        <FormattedMessage id="Your signature here doesn't need to be perfect, it can be hard to sign on a screen. Don't worry about it, we'll still be able to start your claim."/>
                                    </div>
                                )}                               
                            </Popup>
                            <Label
                                label={intl.formatMessage({ id: "{name} Sign Here" }, { name: [compensate.firstName, compensate.surname].join(" ") })}
                            />
                            <div className="signature" style={{ background: '#F2F2F2 0% 0% no-repeat padding-box', border: '1px solid #707070' }}>
                                <SignatureCanvas
                                    penColor='black'
                                    canvasProps={{ height: 150, width: signaturePadWidth }}
                                    onEnd={() => {
                                        setSignature(signatureRef.current._sigPad.toDataURL("image/png"))
                                        setsignError(false)

                                    }}
                                    ref={signatureRef}
                                    
                                />
                             
                            </div>
                            <div className="row g-0">
                            <Button_
                                label={<FontAwesomeIcon icon={["fa" , "rotate"]}/>}
                                onClick={clearSig1}
                                style={{textAlign: 'center', float: "right", minWidth: "0", marginTop: "-157px", zIndex: "1000", position: "relative"}}
                                />
                            { signatureOverlay && <Button_ 
                                className={'remove-animation'}
                                label={[<FontAwesomeIcon icon={["fa" , "signature"]} size="4x"/>, <br/> ,<FormattedMessage id="Click here and write your signature as it appears on your ID"/>]}
                                onClick={() => setSignatureOverlay(false)}
                                style={{textAlign: 'center', minWidth:'100%', height: '162px', marginTop: '-180px', zIndex: "2000", position: "relative", backgroundColor: '#198754', borderRadius: '0'}}
                            />}
                            {signError?
                                 <div>
                                    <span className="text-danger">
                                        <FormattedMessage id="Please sign above"/>
                                    </span>
                                </div>
                                :<></>
                              }
                            
                            <div className="t-and-c-check">
                                <ListRadioOptions
                                    items={[{ text:intl.formatMessage({ id:"Click here to confirm the below"}), value: true} ]}
                                    onSelect={() => setTermsConditions(!termsConditions)}
                                    value={termsConditions}
                                    className="ps-0 no-radius"
                                />
                                {termError && termsConditions === undefined?
                                 <div>
                                    <span className="text-danger">
                                    <FormattedMessage id="Please check the terms and conditions checkbox"/>
                                    </span>
                                </div>
                                :<></>
                                }
                                {currentUrl.includes('airdelayed.com') && <> <FormattedMessage id="Air Delayed and Air Travel Claim are trading styles of Legal Ventures Ltd, company registration number 13100030. Air Travel Claim will manage your claim and communicate with you regarding progress of your claim." />
                                <br />
                                <br />
                                </>}
                                <p className="sig-more-info" >{intl.formatMessage({ 
                                    id:  "By signing above, you agree to our term and conditions"},
                                {   termsAndConditions: <Popup trigger={<button style={{background: 'unset', padding:'0', color:'blue'}}><FormattedMessage id="Terms and Conditions"/></button>} modal>
                                                {close => (
                                                    <div style={{ height: '500px', overflowY: 'auto'}}>
                                                        <Button label='X' style={{position: 'absolute', right:'0', top: '0', borderRadius: '0', backgroundColor: '#198754', padding: '2px 5px', fontSize: 'smaller'}} onClick={close}>

                                                            X
                                                        </Button>
                                                        <AtcTerms />
                                                    </div>
                                                )}
                                            </Popup>, 
                                    mlmlink: <Popup trigger={<button style={{background: 'unset', padding:'0', color:'blue', textAlign:'left'}}><FormattedMessage id="My Law Matters Terms and Conditions"/></button>} modal>
                                                {close => (
                                                    <div style={{ height: '500px', overflowY: 'auto'}}>
                                                        <Button label='X' style={{position: 'absolute', right:'0', top: '0', borderRadius: '0', backgroundColor: '#198754', padding: '2px 5px', fontSize: 'smaller'}} onClick={close}>
                                                            X
                                                        </Button>
                                                        <MlmTerms />
                                                    </div>
                                                )}
                                            </Popup>})}</p>
                                </div>
                            </div>
                            <p className="sig-more-info" ><FormattedMessage id="By clicking the next button you are submitting your details to Air Travel Claim to continue."/></p>
                        </div>
                    </div>

                    {/*
                        otherClientSign !== undefined &&
                        <>
                            <div className="col-md-12">
                                <div className="form-block">
                                    <br />
                                    <Label
                                        label={intl.formatMessage({ id: "{name} Sign Here" }, { name: [clients[otherClientSign].firstname, clients[otherClientSign].lastname].join(" ") })}
                                    />
                                    <div style={{ background: '#F2F2F2 0% 0% no-repeat padding-box', border: '1px solid #707070' }}>
                                        <SignatureCanvas
                                            penColor='black'
                                            canvasProps={{ height: 150, width: signaturePadWidth }}
                                            onEnd={() => {
                                                const clients_ = cloneDeep(clients);
                                                clients_[otherClientSign].signature = signatureRsef.current._sigPad.toDataURL("image/png");
                                                setClients(clients_);
                                            }}
                                            ref={signatureRsef}


                                        />                               
                                    </div>
                                    <div className="row g-0">
                                    <Button_ 
                                    label={<FontAwesomeIcon icon={["fa" , "rotate"]}/>}
                                    onClick={() => {
                                        signatureRsef.current.clear();
                                        clients[otherClientSign].signature = false
                                    }}
                                    style={{textAlign: 'center', float: "right", minWidth: "0", marginTop: "-157px", zIndex: "1000", position: "relative"}}
                                    />
                                    <div className="t-and-c-check">
                                        <ListRadioOptions
                                            items={[{ text:"Click here to confirm the below", value: true} ]}
                                        onSelect={() => setTermsConditions2(!termsConditions2)}
                                            value={termsConditions2}
                                            className="ps-0 no-radius"
                                        />
                                        <Label label={intl.formatMessage({ 
                                                id: "By signing above, I confirm that I have read and agree to the Air Travel Claim {atclink} and {atcpp} and to the My Law Matters {mlmlink}. I agree for my signature to be reproduced on all necessary documentation in the pursuit of the claim on behalf of myself and any other passengers in my party. I consent for Air Travel Claim to investigate my claim and, if it is deemed valid, I consent for the claim to be transferred to My Law Matters." },
                                            {   atclink: <a href="https://app.airtravelclaim.com/atc-terms.pdf" target="_blank" rel="norefferer"><FormattedMessage id="Terms and Conditions"/></a>, 
                                                mlmlink: <a href="https://app.airtravelclaim.com/mlm-terms.pdf" target="_blank" rel="norefferer"><FormattedMessage id="Terms and Conditions"/></a>,
                                                atcpp: <a href="https://airtravelclaim.com/privacy-policy/" target="_blank" rel="norefferer"><FormattedMessage id="Privacy Policy"/></a>})}/>

                                    </div>
                                </div>
                                </div>
                            </div>
                        </>
                                        */}

                    {/*<p className="sig-more-info"><FormattedMessage id="Use of your data:
                    We will use your details to keep in touch with you regarding your claim(s). We will also send you future communications by post,
                    telephone, email, text message or social media messaging (including WhatsApp and Facebook Messenger). These communications
                    will relate to similar claims products of Legal Ventures Limited and its brands.
                                    To opt out of these communications at any time please email compliance@airtravelclaim.com"/></p>*/}
                    <div className="col-md-6 text-center mx-auto">

                        <FormActionButton
                            onNext={() => {
                                if(signature === '' || signature === undefined){ setsignError(true) }
                                if(termsConditions === '' || termsConditions === undefined){ setTermError(true) }

                                if (
                                    (signature && termsConditions) 
                                    //( otherClientSign === undefined || (clients[otherClientSign].signature && termsConditions2))
                                ) {
                                    submitAction({
                                        signature,
                                        termsConditions,
                                        clients
                                    }, (COMPENSATE_SCREEN.SIGNATURE + 1)).then(data => {
                                        // if (data?.caseId) {
                                        //     onNext({
                                        //         signature,
                                        //         termsConditions,
                                        //         clients
                                        //     }, (COMPENSATE_SCREEN.SIGNATURE + 1))
                                        // }
                                    })
                                }
                            }}
                            onBack={() => {
                                onBack(COMPENSATE_SCREEN.SIGNATURE - 1)
                            }}
                        />
                        <br/>
                        <img src={`/img/light-bulb.svg`} className="light-bulb" alt="Light bulb"/><b className='fst-italic'><FormattedMessage id="SIGNATURE encouragement text"/></b>
                    </div>

                </div>
            </div>

        </>
    )
}