import React from 'react'
import FlightList from '../shared/FlightList/FlightList'
import { COMPENSATE_SCREEN } from '../../constants'

export default function LastLegFlightSearch({ compensate, onNext, onBack, isSubmitted }) {

  const nextPage = (validClaim,jurisdiction) =>{
    return compensate.isMissedConnection && compensate.hasBoardedReplacement
      ? COMPENSATE_SCREEN.REPLACEMENT_FLIGHT_DATE : validClaim ||( compensate.hasBoardedReplacement === false && jurisdiction !== 'Other')
        ? COMPENSATE_SCREEN.DELAY_REASON : COMPENSATE_SCREEN.No_CLAIM
  }

  const previousPage = () =>{
    return COMPENSATE_SCREEN.LAST_LEG_FLIGHT_DATE
  }

  return (
    <FlightList 
      onBack={onBack}
      onNext={onNext}
      compensate={compensate} 
      nextPage={nextPage}
      previousPage={previousPage}
      departure={compensate?.connectingFlights[compensate.stops.length]?.departureAirport }
      destination={compensate?.connectingFlights[compensate.stops.length]?.destinationAirport}
      lastLeg
      isSubmitted={isSubmitted}
    />
  )
}
