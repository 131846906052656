import React from "react";
import Accordion from 'react-bootstrap/Accordion';

const FAQ = () => {
    return (
        <div className="faq-wrapp gap-top gb">
            <div className="faq-cnt">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="text-center">
                                <h2 className="title mb-3">FAQs</h2>
                            </div>
                            <div className="gap-top-sm">
                                <div className="accordion">
                                    <Accordion defaultActiveKey="" >
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header><span>What are my rights as a passenger?</span></Accordion.Header>
                                            <Accordion.Body>
                                                <p>People who want to start their long-awaited holiday or return home after their trip usually rely on flights taking off as planned. Unfortunately, it happens again and again that flights are delayed, cancelled or the connecting flight is missed. The good news for affected passengers: In such cases, travellers have extensive rights under EU Air Passenger Rights Regulation 261/2004 and may be entitled to a ticket refund or compensation. This is at least some recompense for the inconvenience caused. The EU Passenger Rights Regulation applies to flights departing from or arriving in the EU. In the second case, the airlines headquarters must also be based in the EU.</p>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="1">
                                            <Accordion.Header><span>What is the difference between a compensation and a ticket refund?</span></Accordion.Header>
                                            <Accordion.Body>
                                                <p>
                                                Flight cancellations, boarding denial, delays, overbookings and missed connections regularly inconvenience passengers. If the airline itself caused the problem, you as a passenger may be entitled to compensation if the problem flight is covered by the EU Passenger Rights Regulation. Compensation under EU law is, so to speak, compensation for passengers due to the time lost and the inconvenience caused.
                                                </p>
                                                <p>
                                                A ticket refund is the repayment of the ticket price. If a flight is cancelled without replacement or the flight times are postponed considerably, the airline must refund your money. Incidentally, in some instances you may be entitled to both a ticket refund and compensation in the event of a flight cancellation.
                                                </p>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="2">
                                            <Accordion.Header><span>What am I entitled to in the event of a flight cancellation?</span></Accordion.Header>
                                            <Accordion.Body>
                                                <p>
                                                If a flight is cancelled, you can claim compensation for the flight cancellation under EU air passenger law if: the airline informs you of the cancellation less than 14 days before the scheduled departure, and the airline itself caused the cancellation. If the flight is cancelled through no fault of the airline, you are entitled to a replacement flight or rebooking, or you can claim back the ticket costs, including any additional costs for seat reservations or luggage. You do not have to accept vouchers. If the cancellation occurs at very short notice, the airline is to blame, and no replacement flight is offered, you can claim both compensation and a ticket refund.
                                                </p>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="3">
                                            <Accordion.Header><span>What am I entitled to in event of a flight delay?</span></Accordion.Header>
                                            <Accordion.Body>
                                                <p>
                                                If your flight arrives at its destination more than 3 hours late, you may be entitled to compensation under EU law if the airline itself is responsible for the flight delay. If there are long waiting times at the airport due to the delay, the airline must provide you with free drinks and snacks. The right to these benefits is also regulated in the EU regulation. Very important: In order to be able to claim compensation for a flight delay, the arrival delay and not the departure delay is relevant.
                                                </p>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="4">
                                            <Accordion.Header><span>What am I entitled to if my flight is overbooked?</span></Accordion.Header>
                                            <Accordion.Body>
                                                <p>
                                                To ensure that flight connections operate at full capacity, airlines regularly overbook flights. If the airline informs you that your flight is overbooked and you cannot travel as planned, you can ask the airline for a prompt replacement flight, compensation or ticket reimbursement. If you are made to wait a long time at the airport, you are also entitled to benefits and provisions for this.
                                                </p>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="5">
                                            <Accordion.Header><span>How much compensation am I entitled to for my problem flight?</span></Accordion.Header>
                                            <Accordion.Body>
                                                <p>The amount of the compensation claim depends on the length of the flightpath. How expensive the flight ticket was is irrelevant for the compensation. For short-haul flights (less than 1,500 kilometres), you are entitled to compensation of £220 per person according to the EU Air Passenger Rights Regulation. For medium-haul flights (up to 3,500 kilometres) you are entitled to £350. For long-haul flights (over 3,500 kilometres), the possible compensation amount is £520.</p>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="6">
                                            <Accordion.Header><span>What rights do I have in the event of flight problems due to Corona?</span></Accordion.Header>
                                            <Accordion.Body>
                                                <p>
                                                If your flight cannot be operated due to a direct connection with Corona and must therefore be cancelled, e.g. due to official restrictions, the airline must reimburse you for the ticket costs within 7 days. You do not have to accept a voucher. However, if you have taken an alternative flight or agreed to a rebooking to a later date, you cannot additionally claim back the ticket price.
                                                </p>
                                                <p>
                                                Since the airline is not to blame for the flight cancellation, it does not have to pay you any compensation. Nevertheless, in some cases Corona is used as an excuse not to pay compensation for a flight cancellation. Due to low-capacity utilisation, it happens quite often that airlines cancel connections for economic reasons. In these cases, you may also be entitled to compensation for an alleged flight cancellation due to Corona.
                                                </p>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="7">
                                            <Accordion.Header><span>Do passenger rights apply in the event of strike action?</span></Accordion.Header>
                                            <Accordion.Body>
                                                <p>
                                                Whenever air traffic control, airport staff or airline employees go on strike, there are frequent delays in flight operations. In principle, you have comprehensive rights as a passenger even during a strike. For example, you are entitled to a replacement transport at the next possible time. If there are long waiting times at the airport, you are entitled to free refreshments in the form of snacks and drinks and even hotel accommodation from your airline.
                                                </p>
                                                <p>
                                                If your departure is delayed by more than 5 hours, you have the right to withdraw from your flight. The airline must then refund the ticket price. If the airline staff go on strike, you may be entitled to compensation in the event of a flight cancellation or a lengthy delay.
                                                </p>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="8">
                                            <Accordion.Header><span>What are extraordinary circumstances?</span></Accordion.Header>
                                            <Accordion.Body>
                                                <p>
                                                In principle, airlines do not have to pay compensation if the cause of the flight problem is beyond their control. In this context, one speaks of a cancellation or delay being caused by extraordinary circumstances. Such extraordinary circumstances include, among others, strike action by air traffic control or airport staff, severe weather conditions, airport and airspace closures, border closures or natural disasters.
                                                </p>
                                                <p>
                                                “If for example, a flight is delayed or cancelled due to a storm, you as a passenger are not entitled to compensation, but the airline still has obligations towards you under the EU Passenger Rights Regulation.”
                                                </p>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="9">
                                            <Accordion.Header><span>After how long can passenger claims still be enforced?</span></Accordion.Header>
                                            <Accordion.Body>
                                                <p>
                                                In the UK, you can assert your claims under EU air passenger law against the airline up to 6 years after your problem flight. Incidentally, the deadline is set to the end of the year. So if you have experienced a flight delay, cancellation or any other flight irregularity anytime in 2021, you can claim compensation or ticket reimbursement until the end of 2027.
                                                </p>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="10">
                                            <Accordion.Header><span>Can I also make claims for a package holiday?</span></Accordion.Header>
                                            <Accordion.Body>
                                                <p>
                                                Your rights under the EU Air Passenger Rights Regulation also apply to package holidays. If your flight is cancelled or delayed, you are therefore entitled to compensation or a ticket refund, even if you booked with a tour operator. If your trip cannot take place and is completely cancelled by the tour operator, e.g. due to Corona, you have the right to a refund of your travel costs.
                                                </p>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="11">
                                            <Accordion.Header><span>How do I get justice in case of a flight problem?</span></Accordion.Header>
                                            <Accordion.Body>
                                                <p>
                                                If you are affected by a flight irregularity, you should check whether you are entitled to compensation from the airline for the inconvenience caused, or whether you can claim a ticket refund. Also, please remember to have the airline confirm the reason for the flight problem in writing. This can be helpful for enforcing your claims later. If the flight problem occurs at very short notice, e.g. if you are already at the airport and there are long waiting times, then be sure to insist on the benefits you are entitled to from the airline.
                                                </p>
                                                <p>
                                                It is recommended to seek professional support to enforce your claims. Passengers who assert their claims on their own are often put off or even completely ignored by the airlines. Trust experienced air passenger rights experts like Airtravel Claim, we will help you successfully enforce your rights. You don’t have to do much at all: simply enter your flight details online at www.airtravelclaim.com. If you have a claim, you can commission Airtravel Claim to enforce your rights. The experts will then take over the case and make sure you get your money from the airline.
                                                </p>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FAQ;