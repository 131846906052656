import { useEffect, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { COMPENSATE_SCREEN, SEND_OTP_ENDPOINT } from "../../constants";
import FormActionButton from "../shared/FormActionButton";
import Text from "../shared/Text";
import axios from "axios";

export default function MobileValidation({ compensate, onNext, onBack }) {
    const intl = useIntl();
    const [otp, setOTP] = useState('');
    const [sentOtp, setSentOtp] = useState(null);

    useEffect(() => {
        if (!compensate.isMobileVerified) {
            const timer = setTimeout(() => {
                sendOtp()
            }, 1000);
            return () => clearTimeout(timer);
        }
    }, [compensate.isMobileVerified]);

    const sendOtp = async () => {
        const randomOtp = Math.floor(Math.random() * (9999 - 1000 + 1)) + 1000;
        setSentOtp(randomOtp);
        return axios.post(SEND_OTP_ENDPOINT(), {
            number: compensate.mobileNumber,
            '2fa': randomOtp
        }).then(data => {
            if (data.data?.error) {
                alert(intl.formatMessage({ id: "Please validate your number" }));
            }
        }).catch(_ => { });
    }

    return (
        <>

            <div className="heading">
                <h2 className="title"><FormattedMessage id="Mobile Validation" /></h2>
            </div>
            <div className="fdc-form gif-input">
                <div className="row">
                    <div className="col-md-12">
                        <div className="form-block">
                            <Text
                                label={intl.formatMessage({ id: "Enter the code we sent to your mobile:" })}
                                onChange={(e) => setOTP(e.target.value)}
                                value={otp}
                                required={false}
                            />
                        </div>
                    </div>
                    <div className="col-md-6 text-center mx-auto">
                        <FormActionButton
                            onNext={() => {
                                if (!compensate.isMobileVerified && (!otp || sentOtp != otp)) {
                                    alert(intl.formatMessage({ id: "Invalid OTP" }))
                                } else {
                                    onNext({
                                        isMobileVerified: true
                                    }, compensate.flightsList.length > 0 ? COMPENSATE_SCREEN.DIRECT_FLIGHT_SEARCH : COMPENSATE_SCREEN.No_CLAIM)
                                }
                            }}
                            onBack={() => {
                                onBack(COMPENSATE_SCREEN.DEPARTURE_DATE)
                            }}
                        />
                    </div>
                </div>
            </div>

        </>
    )
}