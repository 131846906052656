import React, { useState } from "react";
import { Link } from "react-router-dom";

const ConatctBlock = () => {
    const [fname, setFname] = useState('');
    const [lname, setLname] = useState('');
    const [email, setEmail] = useState('');
    const [number, setNumber] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState(false);

    const sendData = () => {
        console.log(fname, lname, email, number, message);
        if (!fname || !lname || !email || !number || !message) {
            setError(true);
            return false;
        }
    }

    const Tell = ({ number, ...props }) => {
        return (
            <a href={`tel:${number}`}>
                {props.children}
            </a>
        );
    }

    const Mailto = ({ email, ...props }) => {
        return (
            <a href={`mailto:${email}`}>
                {props.children}
            </a>
        );
    }

    return (
        <div className="contact-block-wrapp gap gb position-relative">
            <div className="container">
                <div className="text-center"><h2 className="title theme-color-2">Contact us </h2></div>
                <div className="row gap-top-sm">
                    <div className="col-md-12 cb-form-cnt-block">
                        <div>
                            <div className="row gx-5">
                                <div className="col-md-4 h-contact-info">
                                    <div>
                                        <div className="pb-3">
                                            <img src="img/call.svg" alt="" className="me-3" />
                                            <Tell number="0330 808 8636">
                                                0330 808 8636
                                            </Tell>
                                        </div>
                                        <div>
                                            <img src="img/email.svg" alt="" className="me-3" />
                                            <Mailto email="info@airtravelclaim.com">
                                                info@airtravelclaim.com
                                            </Mailto>
                                        </div>
                                    </div>
                                    <div className="d-flex">
                                        <Link to="" className="me-4"><img src="img/insta.svg" alt="" /></Link>
                                        <Link to=""><img src="img/fb.svg" alt="" /></Link>
                                    </div>
                                </div>
                                <div className="col-md-8 cb-form-block">
                                    <div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-block only-bottom-border">
                                                    <input type="text" placeholder="First Name*"
                                                        value={fname}
                                                        onChange={(e) => { setFname(e.target.value) }}
                                                    />
                                                    {error && !fname && <div className="error">Enter Valid First name</div>}
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-block only-bottom-border">
                                                    <input type="text" placeholder="Last Name*"
                                                        value={lname}
                                                        onChange={(e) => { setLname(e.target.value) }}
                                                    />
                                                    {error && !lname && <div className="error">Enter Valid Last name</div>}
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-block only-bottom-border">
                                                    <input type="text" placeholder="Email Address*"
                                                        value={email}
                                                        onChange={(e) => { setEmail(e.target.value) }}
                                                    />
                                                    {error && !email && <div className="error">Enter Valid Email</div>}
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-block only-bottom-border">
                                                    <input type="text" placeholder="Contact Number*"
                                                        value={number}
                                                        onChange={(e) => { setNumber(e.target.value) }}
                                                    />
                                                    {error && !number && <div className="error">Enter Valid Number</div>}
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-block only-bottom-border">
                                                    <textarea placeholder="Your Message*"
                                                        value={message}
                                                        onChange={(e) => { setMessage(e.target.value) }}
                                                    ></textarea>
                                                    {error && !message && <div className="error">Enter Valid Message</div>}
                                                </div>
                                            </div>
                                            <div className="col-md-12 text-center">
                                                <div className="form-block">
                                                    <Link to="/home" className="btn-w color3" onClick={sendData}>Send</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 h-contact-info contact-info-mob">
                                    <div>
                                        <div className="pb-3">
                                            <img src="img/call.svg" alt="" className="me-3" />
                                            <Tell number="0330 808 8636">
                                                0330 808 8636
                                            </Tell>
                                        </div>
                                        <div>
                                            <img src="img/email.svg" alt="" className="me-3" />
                                            <Mailto email="info@airtravelclaim.com">
                                                info@airtravelclaim.com
                                            </Mailto>
                                        </div>
                                    </div>
                                    <div className="d-flex">
                                        <Link to="" className="me-4"><img src="img/insta.svg" alt="" /></Link>
                                        <Link to=""><img src="img/fb.svg" alt="" /></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div class="circle md orange left bottom"></div>
            <div class="circle md blue right"></div> */}
        </div>
    )
}

export default ConatctBlock;