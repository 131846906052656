import React from 'react'

export default function AtcTerms() {
  return (<>
    <div>
    <h1>Air Travel Claim Terms of Engagement (Agreement)</h1>
      <p><em>Please keep this document safe</em></p>
      <p>Air Travel Claim (ATC) is a trading style of Legal Ventures Ltd, registered at Swinford House, Albion Street, Brierley Hill, DY5 3EE and is registered in England and Wales with company registration number 13100030. VAT registration number 431840906. ICO Reg No. ZA886718. This document sets out the Terms of Engagement.</p>

      <h2>Definitions and Interpretation</h2>
      <p><strong>"Airline"</strong> means the business to whom the Letter of Authority (LOA) is addressed, who You are pursuing a Claim(s) against.</p>
      <p><strong>"Agreement"</strong> means the Terms of Engagement set out herein.</p>
      <p><strong>"Cancellation Period"</strong> means 14 days after the date that You sign the Agreement to Proceed.</p>
      <p><strong>"Claim(s)"</strong> means Your Claim(s) against the Airline in relation to Your flight delay Claim(s).</p>
      <p><strong>"Claims Services"</strong> means preparing, submitting, and negotiating Your Claim(s) to/with the Airline. Where appropriate, this may include commencing legal proceedings.</p>
      <p><strong>"Compensation"</strong> means any sums offered, paid or given in respect of a settlement, goodwill gesture, rebate or any other payment associated with the Claim(s).</p>
      <p><strong>"Court"</strong> means a court in HM Courts Service.</p>
      <p><strong>"Disbursements"</strong> means payments made on Your behalf to others involved in the case. These may include Court fees, barrister fees and/or courier charges. This is not an exhaustive list.</p>
      <p><strong>"Fee(s)"</strong> means the amount You will have to pay to ATC for the Claims Services.</p>
      <p><strong>"Instructions"</strong> means Your formal Instructions to Us to commence the Claims Services by signing and returning an Agreement to Proceed.</p>
      <p><strong>"Letter of Authority"</strong> means the document that will be sent to the Airline that evidences Your authority for us to act on Your behalf.</p>
      <p><strong>"Terms"</strong> means these Terms of Engagement.</p>
      <p><strong>"You/Your"</strong> means the individual whose details are detailed in the Letter of Authority. Unless the context otherwise requires, words in the singular include the plural and in the plural include the singular.</p>

      <h2>1. What WILL Air Travel Claim do for You?</h2>
      <p>Upon receipt of Your signed Terms, we will conduct a free flight disruption check to establish whether You have a valid claim. To assist, please supply a copy of Your booking reference. If You don't have a copy, we will contact Your Airline to try and find out the information about the flight.</p>
      <p>If we determine that You have a valid Claim(s) which fall under UK legal jurisdiction, we will contract with our UK Legal Partner, My Law Matters, who may investigate Your Claim(s) in relation to Your and any additional passenger(s) flight delay, denied boarding or flight cancellation, including lost, delayed or damaged baggage Claim(s). My Law Matters shall pass information they receive from the Airline to us, and we shall conclude the investigation. This is included in the free flight disruption checking service.</p>
      <p>My Law Matters may contact Your Airline to make a Claim(s) on Your behalf, which could include making a complaint with the Airline’s alternative dispute resolution scheme, or issuing Court proceedings against the Airline on Your behalf if the Airline does not uphold Your Claim(s).</p>
      <p>My Law Matters is a trading style of Fentiman Legal Ltd. Fentiman Legal Ltd is registered in England and Wales under registration number 12661534, registered office 84 Salop Street, Wolverhampton, West Midlands, WV3 0SR, United Kingdom, authorised and regulated by the Solicitors Regulation Authority (number 800557).</p>
      <p>If we determine You have a valid EU jurisdiction claim, we will submit a complaint on Your behalf to the Airline. If liability is denied, we will then refer Your claim on to one of our European Legal Partners, within the relevant jurisdiction.</p>

      <h2>2. What WON'T Air Travel Claim do for You?</h2>
        <p>Give/offer You legal advice.</p>
        <p>Contract with My Law Matters or one of our European Legal Partners if in our opinion You have no realistic chance of success, and we reserve the right to cancel this Agreement in this event.</p>

      <h2>3. What do we require You to do?</h2>
        <p>Provide relevant information we may request without delay, enabling us to assess Your Claim(s) eligibility.</p>
        <p>Provide us with clear Instructions.</p>
        <p>Fully co-operate with us.</p>
        <p>Not mislead us, provide false information or ask us to act in an improper or unreasonable way.</p>
        <p>Provide us with the authority to act on Your behalf.</p>
        <p>By signing these Terms You are instructing us that You have the consent of all other additional passengers listed to make Claim(s) on their behalf and You are agreeing to be the lead passenger.</p>
        <p>For passengers under the age of 18, You consent that the lead passenger may act as the Litigation Friend to that minor, and grant permission for us to settle any claim on behalf of any minors without the necessity for an Infant Approval Hearing.</p>

      <h2>4. Fees</h2>
      <h3>UK Jurisdiction</h3>
        <p>We will conduct a free flight disruption checking service.</p>
        <p>We will only charge a fee if Your claim is successful.</p>
        <p>If we undertake the Claims Services and are not successful in obtaining Compensation then You owe us nothing.</p>
        <p>If we are successful in obtaining an offer or payment of Compensation then You owe us the Fee of 35% of the Compensation which has been offered or received. For example if You receive Compensation of £520, the Fee would be £182 plus VAT. This is applicable to all additional passenger Claim(s).</p>
        <p>Fees are subject to VAT at the prevailing rate as determined by HM Revenue and Customs.</p>
        <p>If legal proceedings are issued, an additional 15% of the Compensation that You have been offered or received will be charged to cover the additional costs involved for litigation. We reserve the right to charge additional Disbursements where necessarily incurred in pursuing the case through litigation.</p>
        <p>In all cases we charge an Administration Fee of £15 + VAT per passenger.</p>
        <p>You agree to authorise the Airline to remit the Compensation to us and agree that we shall deduct the Fee(s) plus VAT before paying the balance of the Compensation to You.</p>
        <p>We will, as soon as is reasonably practicable following the payment of Compensation by the Airline, pay You the balance of the Compensation, after deducting all specified Fee(s). Where You have more than one successful Claim against one or more Airlines, we may deduct from any Compensation paid the Fee(s) plus VAT payable in respect of each of the Claim(s).</p>
        <p>Fee(s) plus VAT become due at the point that either we or You are informed of an offer of Compensation and payable from the date You receive Your Compensation. If the Airline offers or pays Compensation to You directly, You agree to notify us immediately (and within seven days at the latest) and to provide the details we need to calculate the Fee(s). For the avoidance of doubt, should You reject a reasonable offer of Compensation the Fee plus VAT remains due on the sum offered and payable by You within seven days of receiving the offer of Compensation.</p>
        <p>If You fail to pay the Fee(s) plus VAT due and payable in accordance with these Terms, We reserve the right to cancel all other active Claims.</p>
        <p>If you fail to notify us of the value of the Compensation offered to you we reserve the right to charge the Fee(s) plus VAT.</p>
      <h2>EU Jurisdiction</h2>
      <p>We will conduct a free flight disruption checking service.</p>
      <p>If we are successful in obtaining a reasonable offer or payment of Compensation then You will owe us the Fee of 35% of the Compensation that You have been offered or receive. This means, for example: if You receive Compensation of €600 the Fee would be €210.</p>
      <p>If our European Legal Partner(s) has to issue Court proceedings, an additional Fee, to cover the cost of litigation, amounting to 15% of the Compensation amount, will be payable.</p>
      <p>We will charge an administration fee of €18 per passenger when we obtain a reasonable offer or payment of Compensation. We reserve the right to charge additional Disbursements where necessarily incurred in pursuing the case through the EU Courts.</p>
      <p>All Fees may be subject to VAT (Value Added Tax) at the prevailing rate applicable to the Jurisdiction.</p>
      <p>You agree to authorise the Airline to remit the Compensation to ATC and agree that ATC shall deduct the Fee(s) plus VAT where applicable before paying the balance of the Compensation to You.</p>
      <p>We will, as soon as is reasonably practicable following the settlement of a Claim(s) and the payment of Compensation by the Airline to us, pay You the balance of the Compensation, after deducting the Fee(s) plus VAT. Where You have more than one successful Claim against one or more Airlines, We may deduct from any Compensation paid the Fee(s) plus VAT payable in respect of each of the Claims.</p>
      <p>Fee(s) plus VAT become due at the point that either We or You are informed of an offer of Compensation and payable from the date You receive Your Compensation. If the Airline offers or pays Compensation to You directly, You agree to notify us immediately (and within seven days at the latest) and to provide the details We need to calculate the Fee(s). Should You reject a reasonable offer of Compensation the Fee plus VAT remains due on the sum offered and payable by You within seven days of receiving the offer of Compensation.</p>
      <p>We will invoice You for the Fee(s) plus VAT which will become immediately payable by You to us from the date You receive Your Compensation.</p>
      <p>We reserve the right to charge for costs and expenses incurred in recovering unpaid Fees plus VAT where an offer or payment of Compensation is made from an Airline to You. These Terms shall continue to operate until all Claims where a Fee(s) plus VAT is payable have been settled.</p>
      <p><em>If You are based in the UK then VAT will be charged at the current rate. For customers who are based outside the UK VAT will only be charged where applicable according to the prevailing statutory regulations. The determination of whether VAT applies will depend on the jurisdiction and specific circumstances of each case.</em></p>
      <p>You acknowledge that touristic vouchers or offers of any other services may not be accepted as Compensation by us.</p>
      <p>You acknowledge that it is the sole decision of ATC whether to accept or reject any offer of a partial Compensation payment made by the Airline.</p>
      <p>If the Airline or Your nominated financial institution receive Your Compensation, applies a Foreign Transaction Fee, You are responsible for the cost of this fee.</p>

      <h2>5. Non-Payment of Fees Due</h2>
      <p>In the event that a fee due to us by You remains unpaid after 45 days then late payment interest will be calculated at 8% per annum from the date that the fee became due until the date payment is made.</p>
      <p>Where fees remain unpaid and ATC are forced to either take Court action or appoint a debt collection agency to recover outstanding monies, ATC reserve the right to increase the amount owed by the amount equivalent to the cost of that recovery. A Court may award additional cost and/or statutory interest at the rate of 8% above the Bank of England rate.</p>

      <h2>6. Cancelling this Agreement</h2>
      <p>We can cancel this Agreement at any time. There is no cancellation fee if we cancel our Agreement to carry out a free flight disruption checking service for You.</p>
      <p>You have the right to terminate this Agreement within 14 days by giving notice, by emailing info@airtravelclaim.com. Any cancellation must be made by way of a cancellation notice or by a clear statement to us. There is no cancellation fee if You ask us not to go ahead with the free flight disruption checking service.</p>
      <p>If You cancel Your Claim(s) within the Cancellation Period You will owe ATC nothing, unless You have received a reasonable offer of Compensation within this period. In this case, ATC shall be entitled to charge You a cancellation charge equal to the Fee(s) plus VAT that would be payable under clause 4 above.</p>
      <p>If You cancel the Claim(s) at any time after the Cancellation Period, ATC may charge a cancellation charge which will be reasonable and proportionate to the work done and the costs incurred by ATC up to the point of cancellation. This cancellation charge will be the time spent on Your Claim(s) by ATC charged at £150 plus VAT per hour. If Your case is referred to one of our EU Legal Partners, You will be provided with a copy of the relevant cancellation process, fees and charges.</p>

      <h2>7. Use of Signature</h2>
      <p>By signing these Terms, You consent for Your signature to be applied to a letter to the Airline requesting they deal directly with Us, or our law partner in UK or EU Jurisdiction, and/or the Airline's alternative dispute resolution scheme form. In respect of any minors, You consent to add Your signature to a Certificate of Suitability to act on their behalf in relation to any Court proceedings and/or legal pack and Letter of Authority.</p>

      <h2>8. Complaints Procedure</h2>
      <p>Should You have a complaint You can contact us by writing to Air Travel Claim at 84 Salop Street, Wolverhampton, WV3 0SR, United Kingdom, by phone on 0330 808 8636 or by email to compliance@airtravelclaim.com. Full details of our complaints handling procedure can be viewed on our website: <a href="https://airtravelclaim.com/complaints-procedure/">https://airtravelclaim.com/complaints-procedure/</a>.</p>

      <h2>9. Data Protection</h2>
      <p>We will hold, control, and process Your personal information in accordance with the Data Protection Act 2018, the UK General Data Protection Regulations and the Privacy and Electronic Communications Regulations. By providing Your personal information to us, You explicitly authorise us to process the information for the purpose set out in this paragraph. You can, at any time, request a copy of all information we hold relating to You by writing to us (a written Data Subject Access Request in accordance with the Data Protection Act/GDPR). We will use the personal information You provide to assess Your claim and carry out our duties in accordance with this Agreement. We may share Your personal information with other companies if necessary, to process Your claim for Compensation, or any financial matters we believe may be of assistance to You.</p>
      <p>Your personal information may also be processed by other organisations, including credit reference agencies, on our behalf for the purpose of processing Your claims and providing information or services to You. The use of Your personal information for these purposes will remain under our control at all times. You can change Your mind at any time. Please visit our privacy policy: <a href="https://airtravelclaim.com/privacypolicy/">https://airtravelclaim.com/privacypolicy/</a>. This will not affect Your credit score.</p>
      <p>We may, from time to time, expand or reduce our business which may involve the sale and/or transfer of control of all or part of Legal Ventures Ltd. In this case, Your data could be transferred along with that part and the new owner or newly controlling party will be permitted to use the data for the purposes for which it was originally supplied to us.</p>

      <h2>10. Other Important Information</h2>
      <p>You are entitled to seek further advice in relation to Your claim and to consider what services might be most appropriate for Your claim for Compensation. In particular You have the right to shop around, or You can contact Your Airline directly. You should also consider whether You have alternative mechanisms for pursuing a claim, for example, legal expenses insurance.</p>
      <p>Each party irrevocably agrees that any proceedings relating to any dispute or claim (including non-contractual disputes or claims) arising out of or in connection with this Agreement or its subject matter shall be governed by and construed in accordance with English law and the parties irrevocably submit to the jurisdiction of the Courts of England and Wales.</p>
      <p>We reserve the right to assign all rights in these Terms and to subcontract to others all or any of our obligations under it.</p>
      <p>I/We have read the Terms & Conditions and agree to be bound by their contents. A copy of the Terms & Conditions can be found on the reverse of the welcome letter. Please keep this document safe, as this is Your contract between You and us. Full T&C's can be viewed here: <a href="http://www.airtravelclaim.com/terms-and-conditions/">www.airtravelclaim.com/terms-and-conditions</a></p>
      <p>v3.1</p>
    </div>
 </> )
}
