import { useEffect, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { COMPENSATE_SCREEN, SEARCH_AIRPORT_ENDPOINT } from "../../constants";
import FormActionButton from "../shared/FormActionButton";
import Label from "../shared/Label";
import AsyncSelect from 'react-select/async';
import axios from "axios";
import { isEmpty } from "lodash";
import ListRadioOptions from "../shared/ListRadioOptions";
import { height } from "@fortawesome/free-regular-svg-icons/faAddressBook";


export default function CheckFlightDetails({ compensate, onNext, onBack }) {
    const intl = useIntl();
    const [departureAirport, setDepartureAirport] = useState(compensate.departureAirport)
    const [destinationAirport, setDestinationAirport] = useState(compensate.destinationAirport)
    const [connectingFlight, setConnectingFlight] = useState(compensate.connectingFlight)
    const [numberOfFlights, setNumberOfFlights] = useState(compensate.numberOfFlights)
    const [isMissedConnection, setIsMissedConnection] = useState(compensate.isMissedConnection)
    const [departureDate, setDepartureDate] = useState()
    const [isClearable, setIsClearable] = useState(true);
    const [isDisabled, setIsDisabled] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [departureError, setDepartureError] = useState(false);
    const [arrivalError, setArrivalError] = useState(false);
    const [connectingFlightError, setConnectingFlightError] = useState(false)
    const [isRtl, setIsRtl] = useState(false);
    
    useEffect(() =>{
        const departure = new URL(window.location.href).searchParams.get('departure')
        const arrival = new URL(window.location.href).searchParams.get('arrival')
        const handleDeparture = async () => {
            const airportData = await fetchAirportsData(departure);
            setDepartureAirport(airportData[0]);
             console.log(airportData)
        };
        const handleArrival = async () => {
            const airportData = await fetchAirportsData(arrival);
            setDestinationAirport(airportData[0]);
             console.log(airportData)
        };

       if(departure && departure.length >= 3){
        handleDeparture();
        }
        if(arrival && arrival.length >= 3){
        handleArrival();
        }


    },[])

    const departur = (event)  =>{
        setDepartureAirport(event)
        departureAirport && departureAirport?.label === undefined ? setDepartureError(true) : setDepartureError(false)
     }


    const arrival = (event)  =>{
        setDestinationAirport(event)
        destinationAirport && destinationAirport?.label === undefined ? setArrivalError(true) : setArrivalError(false)

    }

  
    const fetchAirportsData = async (search) => {
        const formatAirports = (data) => data?.map(airport => ({ label: airport.airportName + ', ' + airport.city + ', ' + airport.country, value: airport.airportCode }));
        if (search.length > 2) {
            return axios.post(SEARCH_AIRPORT_ENDPOINT(), { 
                "destination": search 
            }).then(data => formatAirports(data.data.message.data)).catch(_ => []);
        }
    }

    // react-select input styles 
    const departureCustomStyles = {
        control: (base) => ({
            ...base,
            display: 'flex',
            flexDirection: 'row-reverse', // This reverses the order of elements
            textAlign: 'center', // Add any custom styles for the control container
          }),
          menu: (base) => ({
            ...base,
            left: 'auto', // Reset the left property
            right: 0, // Position the dropdown on the right side
            // Add any other custom styles you want for the menu
          }),
          clearIndicator: (base) => ({
            ...base,
            order: 1
            // Adjust the position of the clear button here
            // For example, you can add styles to move it to the right
            // Example: marginRight: '10px'
          }),
          menuList: (base) => ({
            ...base,
            // Add any custom styles for the menu list
          }),
          loadingIndicator: (base) =>  ({
            ...base,
            order: 3
          }),
        dropdownIndicator: (base, state) => ({
          ...base,
          transition: 'all .2s ease',
          transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
          order: 2
          // Add any other custom styles you want for the dropdown indicator
        }),
        indicatorSeparator: (base) => ({
          ...base,
          display: 'none',
        }),
        dropdownIndicatorContainer: (base) => ({
          ...base,
          padding: '4px',
        }),
        customDropdownIndicator: {
          // Add any custom styles for the dropdown indicator
          // that won't affect other classes
        },
      };
      
    const customStyles = {
        control: (base) => ({
            ...base,
            textAlign: 'center'
        }),
        dropdownIndicator: (base, state) => ({
          ...base,
          transition: 'all .2s ease',
          transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
          // Add any other custom styles you want for the dropdown indicator
        }),
        indicatorSeparator: (base) => ({
          ...base,
          display: 'none',
        }),
        dropdownIndicatorContainer: (base) => ({
          ...base,
          padding: '4px',
        }),
        customDropdownIndicator: {
          // Add any custom styles for the dropdown indicator
          // that won't affect other classes
        },
      };
      

    return (
        <>
            <div className="heading d-flex justify-content-center align-items-center uplers-icon fdc-form">
                <img src="/uplers-icons/icon1.svg" alt="icon" />
                <h2 className="title"><FormattedMessage id="Let's check your flight details" /></h2>
            </div>
            <div className="fdc-form gif-input">

                <div className="col-md-12 mb-3">
                    <div className="box-message fw-semibold theme-color-1">
                        <FormattedMessage id="Our flight compensation check is a free service and will only take a few moments to complete. We will confirm if you have a valid claim before starting your claim process." />             
                    </div>
                </div>

                <div className="row">

                    <div className="col-md-6">
                        <div className="form-block">
                            <Label label={intl.formatMessage({ id: "Departure Airport" })} />
                            <AsyncSelect
                                value={departureAirport}
                                loadOptions={fetchAirportsData}
                                onChange={(selectedOption) => departur(selectedOption)}
                                styles={departureCustomStyles}
                                noOptionsMessage={() => "Start typing to see results"}
                                components={{
                                    DropdownIndicator: (props) => (
                                      <div className="custom-dropdown-indicator pe-2">
                                            <img src="/uplers-icons/flight-list-icon.svg" alt="departure-icon" />
                                      </div>
                                    ),
                                  }}
                                placeholder={intl.formatMessage({ id: "Type here to see results"})}
                                isDisabled={isDisabled}
                                isLoading={isLoading}
                                isClearable={isClearable}
                                isRtl={isRtl}

                            />
                                 {departureError ?
                                 <div>
                                    <span className="text-danger">
                                        <FormattedMessage id="Please Select the Departure Airport" />
                                    </span>
                                </div>
                                :<></>
                              }
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-block">
                            <Label label={intl.formatMessage({ id: "Final Destination Airport" }) } />
                            <AsyncSelect
                                value={destinationAirport}
                                loadOptions={fetchAirportsData}
                                onChange={(selectedOption) => arrival(selectedOption)}
                                noOptionsMessage={() => "Start typing to see results"}
                                styles={customStyles}
                                components={{
                                    DropdownIndicator: (props) => (
                                      <div className="custom-dropdown-indicator ps-2">
                                            <img src="/uplers-icons/flight-list-icon2.svg" alt="destination-icon" />
                                      </div>
                                    ),
                                  }}
                                placeholder={intl.formatMessage({ id: "Type here to see results"}) }
                                isDisabled={isDisabled}
                                isLoading={isLoading}
                                isClearable={isClearable}
                                isRtl={isRtl}

                            />

                             {arrivalError?
                                 <div>
                                    <span className="text-danger">
                                        <FormattedMessage id="Please Select the Destination Airport"/>
                                    </span>
                                </div>
                                :<></>
                              }
                        </div>
                    </div>

                    <div className="col-md-12">
                        <div className="form-block mb-0">
                            <ListRadioOptions
                                label={intl.formatMessage({id: "Did you have any connecting flights within this booking?"})}
                                items={[{ text: intl.formatMessage({ id:"Yes"}), value: true }, { text: intl.formatMessage({ id:"No"}), value: false }]}
                                onSelect={(val) => {
                                    setConnectingFlight(val)
                                    setConnectingFlightError(false)
                                }}
                                value={connectingFlight}
                                className="d-inline-block"
                                divStyle="full-width"
                            />
                        </div>
                    </div>
                    {connectingFlightError?
                                 <div>
                                    <span className="text-danger">
                                        <FormattedMessage id="Please select an option"/>
                                    </span>
                                </div>
                                :<></>
                              }


                    <div className="col-md-6 text-center mx-auto">
                        <FormActionButton
                            onNext={() => {
                               if(departureAirport === '' || departureAirport === undefined){ setDepartureError(true) }
                               if(destinationAirport === '' || destinationAirport === undefined){ setArrivalError(true) }
                               if(connectingFlight !== true && connectingFlight !== false){setConnectingFlightError(true)}

                               if (departureAirport && destinationAirport && (connectingFlight === true || connectingFlight === false)) {
                                   onNext({
                                        departureAirport,
                                        destinationAirport,
                                        departureDate,
                                        connectingFlight,
                                        numberOfFlights: connectingFlight ? numberOfFlights : 0,
                                    }, (connectingFlight ? COMPENSATE_SCREEN.CONNECTING_FLIGHTS: COMPENSATE_SCREEN.DIRECT_FLIGHT_DATE ))
                                }
                            }}
                        />
                      <br/>
                      <img src={`/img/light-bulb.svg`} className="light-bulb" alt="Light bulb"/><b className="fst-italic"><FormattedMessage id="START_DESTINATION encouragement text"/></b>
                    </div>
                </div>

            </div>
        </>
    )
}
