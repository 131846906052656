import React from "react";
import Header from "./Header";
import Testimonial from "./Testimonial";
import ConatctBlock from "./ConatctBlock";
import Footer from "./Footer";
import InnerBanner from "./InnerBanner";

const Solutions = () => {
    return (
        <div className="solutions-wrapp">
            <Header bg='#ffffff'/>
            <InnerBanner pageName="Solutions"/>
            <Testimonial />
            <ConatctBlock />
            <Footer />
        </div>
    )
}

export default Solutions;