import React, { useState, useEffect } from 'react';
import { GOOGLE_REVIEW_ENDPOINT } from '../../../constants';
import axios from 'axios';

const GoogleRatingDisplay = () => {
  const [rating, setRating] = useState(null);


  useEffect(() => {
    const fetchGoogleReviews = async () => {
      try {
        const response = await axios.get(GOOGLE_REVIEW_ENDPOINT(),{
          headers:
            {
              'X-Frontend-Identifier': window.location.href.includes('airdelayed.com')
              ? 'Air Delayed' 
              : 'Air Travel Claim'
              }
        });
        const fetchedRating = response.data.result?.rating;
        setRating(fetchedRating);
      } catch (error) {
        console.error('Error fetching Google Reviews', error);
      }
    };

    fetchGoogleReviews();
  }, []);

  const renderStars = () => {
    const roundedRating = Math.round(rating); // Round to the nearest whole number

    const stars = [];
  
    for (let i = 0; i < roundedRating; i++) {
      stars.push(<span key={i}>&#9733;</span>); // Unicode for a solid star
    }
  
    for (let i = 0; i < 5 - roundedRating; i++) {
      stars.push(<span key={i + roundedRating}>&#9734;</span>); // Unicode for an empty star
    }
  
    return stars;
  };


  return (
    <div>
      {rating !== null ? (
          <div className="star-rating">EXCELLENT {renderStars()} <img src='/img/google-logo.svg' alt='google logo' className='google'/></div>
      ) : (
        <p>Loading rating...</p>
      )}
    </div>
  );
};

export default GoogleRatingDisplay;
