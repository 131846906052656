import { FormattedMessage, useIntl } from "react-intl"
import Button_ from "../shared/Button";
import { useEffect, useState } from "react";
import { COMPENSATION_SUBMIT_ENDPOINT, COMPENSATE_NOCLAIM_REASON, COMPENSATE_SCREEN } from "../../constants";
import PhoneInput from "react-phone-number-input";
import { isValidMobile, isValidEmail } from "../../utils/utils";
import EmailInput from "../shared/EmailInput";
import axios from "axios";
import FormActionButton from "../shared/FormActionButton";
import ListRadioOptions from "../shared/ListRadioOptions";

export default function NoClaim({ compensate, onNext }) {

  const [emailAddress, setEmailAddress] = useState(compensate.emailAddress ?? '')
  const [mobileNumber, setMobileNumber] = useState(compensate.mobileNumber ?? '')
  const [deniedBoarding, setDeniedBoarding] = useState(compensate.DenaiedBoardingClaim)
  const [dataStoreId, setDataStoreId] = useState('')

  const [validemailError, setValidEmailError] = useState(false);
  const [validMobileError, setvalidMobileError] = useState(false);
  const [coolOfText, setCoolOfText] = useState(false)

  const intl = useIntl();

  const setEmail = (event) => {
    setEmailAddress(event)
    if (!isValidEmail(event) ) {
        setValidEmailError(true)
        
    } else {
        setValidEmailError(false) 
    }
  }

  const setMobile = (event) => {
      if (typeof event === 'undefined') {
          setMobileNumber('');
        } else {
          setMobileNumber(event)
        } 
      if (!isValidMobile(event) && event !== undefined ) { setvalidMobileError(true) }else { setvalidMobileError(false) }
  }

  useEffect(()=>{ 
    /* eslint-disable no-undef */
    if (typeof klaviyo !== 'undefined') {
      klaviyo.push(['track', 'No Claim', compensate?.flightData]);
    }
    /* eslint-enable no-undef */
    axios.post(COMPENSATION_SUBMIT_ENDPOINT, {
      web_form_id: "flight_delays_noclaim",
      flights: {
              [compensate?.connectingFlight ? "affectedFlight" : "directFlight"]: compensate?.flightData,
              lastLeg: compensate?.lastLeg,
              replacementFlight: compensate?.replacementFlight
            },
      tradingStyle: window.location.href.includes('airdelayed.com') 
      ? 'Air Delayed'
      : window.location.href.includes('flightclaims.co.uk')
        ? 'Flight Claims'
        : 'Air Travel Claim',
      email: compensate?.emailAddress
      
    }).then((res) => 
    {
      console.log(res.data)
      setDataStoreId(res.data.dataid)
    })
  },[])

  const appendContactDetails = async() => {
    await axios.put(`${COMPENSATION_SUBMIT_ENDPOINT}/${dataStoreId}`,{
      web_form_id: "flight_delays_noclaim",
      email: emailAddress,
      mobile: mobileNumber
    }).then((res) => {
      console.log(res.data)
      setCoolOfText(true)
    })
  }

  const refreshPage = () => {
    window.location.reload(false);
  }

  const jurisdiction = (compensate?.replacementFlight?.jurisdiction ||
  compensate?.lastLeg?.jurisdiction ||
  compensate?.flightData?.jurisdiction )

  const priorityReason = "Airport and Airline out of Jurisdiction";
  const decisionReason =
    (compensate?.replacementFlight?.decisionReason?.includes(priorityReason) ||
     compensate?.lastLeg?.decisionReason?.includes(priorityReason) ||
     compensate?.flightData?.decisionReason?.includes(priorityReason)) 
      ? priorityReason
      : (compensate?.replacementFlight?.decisionReason?.[0] ||
         compensate?.lastLeg?.decisionReason?.[0] ||
         compensate?.flightData?.decisionReason?.[0] ||
         'Unknown');
  return (
    <>
      {coolOfText === false ? <>
        <div className="heading uplers-icon fdc-form">
          <img src="/uplers-icons/icon4.svg" alt="icon" style={{display: "block", margin: "auto", marginBottom: "30px"}}/>
        <h2 className="title"><FormattedMessage id="We are sorry, you do not have a claim based on the information provided." /></h2>
      </div>
      <div className="box-message fw-semibold theme-color-1 text-center">
        <h6> 
          <FormattedMessage id="No Claim Reason: "/>
          {COMPENSATE_NOCLAIM_REASON[decisionReason] || decisionReason}
        </h6>
      </div>          
        { (compensate.flightData?.delay > 120 && compensate.flightData?.delay <= 175 && !compensate.connectingFlight) || jurisdiction === "Other"
          ? <></> 
          :<div className="fdc-form gif-input">
            <div className="col-md-12">
              <div className="form-block mb-0">
                <ListRadioOptions
                  label={intl.formatMessage({id: "Were you denied boarding because your flight was over booked?"})}
                  items={[{ text: intl.formatMessage({ id:"Yes"}), value: true }, { text: intl.formatMessage({ id:"No"}), value: false }]}
                  onSelect={setDeniedBoarding}
                  value={deniedBoarding}
                  className="d-inline-block"
                  divStyle="full-width"
                />
              </div>
            </div>
          </div>
        }
        
        { compensate.flightData?.delay > 120 && compensate.flightData?.delay <= 175 && !compensate.connectingFlight && jurisdiction !== "Other" &&
        <>
        <div className="box-message fw-semibold theme-color-1 text-center">
          <h6>
          <FormattedMessage id="Your flight is currently showing as delayed, but it's just shy of the 3-hour threshold needed to make a claim. Airlines often update their records with the final delay time within 72 hours of landing, so we'll double check your claim for you." />
          </h6>
          <br />
          <FormattedMessage id="Please provide your details below so we can update you once our final check is complete." />
          <br />
          <br />
        </div>
        <div className="fdc-form gif-input">
          <div className="col-md-12">
            <div className="form-block">
                <EmailInput
                    label={intl.formatMessage({ id: "Please provide your email address" })}
                    onChange={(e) => setEmail(e.target.value)}
                    value={emailAddress}
                    required={false}
                    id="Email Address"
                />

                {validemailError ?
                      <div>
                        <span className="text-danger">
                            <FormattedMessage id="Please enter a valid email address"/>
                        </span>
                    </div>
                    :<></>
                  }
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-block">
              <label htmlFor="mobile"><FormattedMessage id="Please provide your Mobile number" /></label>
                <PhoneInput
                  international
                  id='mobile'
                  countryCallingCodeEditable={false}
                  defaultCountry="GB"
                  value={mobileNumber}
                  onChange={setMobile}
                  style={{border: '1px solid #001A42', paddingLeft: "10px", borderRadius: "0.375rem" }}
                />
                {validMobileError ?
                  <div>
                    <span className="text-danger">
                        <FormattedMessage id="Please enter a valid Mobile Number"/>
                    </span>
                </div>
                :<></>}
            </div>
          </div>
          <i><b><FormattedMessage id="We ask for this information to make sure we can keep you updated on your claim." /></b></i>
        </div>
      </>}
      {deniedBoarding === true ?
        <div className="fdc-form gif-input pt-0">
          <div className="col-md-6 text-center mx-auto">
            <FormActionButton 
              onNext={() => {
                onNext({
                  deniedBoardingClaim: deniedBoarding,
                  delayReason3: 'Denied Boarding'
                },(COMPENSATE_SCREEN.PASSENGERS))
              }}
            />
          </div>
        </div>
        : deniedBoarding === false &&
          <div className="box-message fw-semibold theme-color-1 text-center pt-0">
            <FormattedMessage id="If you would like to try again. Please click the button below to enter and check your flight details." />
            <Button_ 
              onClick={refreshPage}
              label="Try Again"
              className={'text-center'}
            />
          </div>
      }
      {compensate.flightData?.delay > 120 && compensate.flightData?.delay <= 175 && !compensate.connectingFlight && jurisdiction !== "Other" &&
      <div className="fdc-form gif-input">
        <div className="col-md-6 text-center mx-auto">
          <FormActionButton 

            onNext={() => {
              if(!isValidEmail(emailAddress)){setValidEmailError(true)}
              if(mobileNumber === '' || mobileNumber === null){setvalidMobileError(true)}
              if(isValidEmail(emailAddress) || mobileNumber !== ''){
                appendContactDetails()
              }}}

          />
        </div>
      </div>
      }
      </> 
      :<div className="fdc-form gif-input">
          <div className="fdc-form gif-input">
            <div className="box-message fw-semibold theme-color-1 text-center">
                <FormattedMessage id="Thank You, we will be in touch once we have double checked your claim. Please look our for our email from us which will direct you to complete your claim submission journey if we identify you have a valid claim. Please add support@... to your contact list to ensure our email arrives safely into your inbox." />
              </div>
          </div>
        </div>}
    </>
  )
}
