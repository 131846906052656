import { useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { COMPENSATE_SCREEN, EACH_PASSENGER_CLAIM } from "../../constants";
import DatePicker from "../shared/DatePicker";
import FormActionButton from "../shared/FormActionButton";
import Select from "../shared/Select";
import Text from "../shared/Text";
import Label from "../shared/Label";
import PhoneInput from "react-phone-number-input";
import { isValidMobile, isValidEmail } from "../../utils/utils";
import EmailInput from "../shared/EmailInput";


export default function Passengers({ compensate, onNext }) {
    const [title, setTitle] = useState(compensate.title ?? 'Mr')
    const [firstName, setFirstName] = useState(compensate.firstName ?? '')
    const [surname, setSurname] = useState(compensate.surname ?? '')
    const [emailAddress, setEmailAddress] = useState(compensate.emailAddress)
    const [emailAddressAgain, setEmailAddressAgain] = useState(compensate.emailAddressAgain ?? '')
    const [mobileNumber, setMobileNumber] = useState(compensate.mobileNumber ?? '')
    const [dob, setDob] = useState(compensate.dob ?? '')
    const [didYouBook, setdidYouBook] = useState(compensate.didYouBook)

    const [fnameError, setFNameError] = useState(false);
    const [lnameError, setLnameError] = useState(false);
    const [dobError, setdobError] = useState(false);
    const [validemailError, setValidEmailError] = useState(false);
    const [emailDoesntMatch, setEmailDoesntMatch] = useState(false)
    const [validMobileError, setvalidMobileError] = useState(false);

 //   const [didubookError, setdidubookError] = useState(false)


    const intl = useIntl();

    const titleOptions = [
        { text: "Mr", value: "Mr" },
        { text: "Mrs", value: "Mrs" },
        { text: "Ms", value: "Ms" },
        { text: "Miss", value: "Miss" },
        { text: "Mx", value: "Mx" },
    ];


    const fname = (event) => {
        setFirstName(event)
        event === '' ? setFNameError(true) : setFNameError(false)
    }
    
    const lname = (event) => {
        setSurname(event)
        event ==='' ? setLnameError(true) : setLnameError(false)
    }

    const setEmail = (event) => {
        setEmailAddress(event)
        if (!isValidEmail(event) ) {
            setValidEmailError(true)
            
        } else {
            setValidEmailError(false) 
        }
    }

    const setMobile = (event) => {
        if (typeof event === 'undefined') {
            setMobileNumber('');
          } else {
            setMobileNumber(event)
          } 
        if (!isValidMobile(event) && event !== undefined ) { setvalidMobileError(true) }else { setvalidMobileError(false) }
    }
    console.log(isValidEmail(emailAddress))
    return (

        <>
            <div className="heading fdc-form uplers-icon">
                <img src={`${process.env.PUBLIC_URL}/img/Person.svg`} className="m-auto d-block" style={{width:'16%'}}/>
                <h2 className="title mt-2" >
                    <FormattedMessage 
                        id="Great news, the information you have provided is valid and we can now start your claim process. Please provide your details to continue." 
                        values={{
                            claimAmount: <b className="text-danger">£{EACH_PASSENGER_CLAIM}</b>
                        }}     
                    />
                </h2> 
            </div>

            <div className="fdc-form gif-input">
                <div className="row">
                    <div className="col-12">
                        <div className="form-block mb-0">
                        <Label label={intl.formatMessage({id:'Please provide your details to continue.'})} />
                        </div>
                    </div>
                    <div className="col-md-2">
                        <div className="form-block">
                            <Select
                                label={intl.formatMessage({ id: "Title" })}
                                options={titleOptions}
                                onChange={setTitle}
                                value={title}
                                id="Title"
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-block">
                            <Text
                                label={intl.formatMessage({ id: "What is your first name?" })}
                                onChange={(e) => fname(e.target.value)}
                                value={firstName}
                                id="First Name"
                                required={false}
                            />
                            {fnameError?
                                 <div>
                                    <span className="text-danger">
                                        <FormattedMessage id="Please enter your first name"/>
                                    </span>
                                </div>
                                :<></>
                              }
                        
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-block">
                            <Text
                                label={intl.formatMessage({ id: "What is your surname?" })}
                                onChange={(e) => lname(e.target.value)}
                                value={surname}
                                id="Surname"
                                required={false}
                            />

                             {lnameError?
                                 <div>
                                    <span className="text-danger">
                                        <FormattedMessage id="Please enter the last name"/>
                                    </span>
                                </div>
                                :<></>
                              }

                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="form-block">
                            <div className="fw-semibold theme-color-1 ">
                                <FormattedMessage id="Please make sure that you enter your names as they appear on your identification." />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="form-block">
                            <DatePicker
                                label={intl.formatMessage({ id: "What is your date of birth?" })}
                                onChange={(formattedDate) => setDob(formattedDate)}
                                value={dob}
                                id="Date of birth"
                                dateOfBirth
                                compensate={compensate}
                            />
                            {dobError?
                                 <div>
                                    <span className="text-danger">
                                        <FormattedMessage id="Please enter your date of birth"/>
                                    </span>
                                </div>
                                :<></>
                              }
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="form-block">
                            <EmailInput
                                label={intl.formatMessage({ id: "Please confirm your email address so that we can provide you with details of your flight check" })}
                                onChange={(e) => setEmail(e.target.value)}
                                value={emailAddress}
                                required={false}
                                id="Email Address"
                            />

                           {validemailError ?
                                 <div>
                                    <span className="text-danger">
                                        <FormattedMessage id="Please enter a valid email address"/>
                                    </span>
                                </div>
                                :<></>
                              }
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="form-block">
                            <EmailInput
                                label={intl.formatMessage({ id: "Please confirm your email address again" })}
                                onChange={(e) => {
                                    if(e.target.value === emailAddress){
                                        setEmailDoesntMatch(false)
                                    }
                                    setEmailAddressAgain(e.target.value)
                                }}
                                value={emailAddressAgain}
                                required={false}
                                id="Email Address Verification"
                            />

                           {emailDoesntMatch ?
                                 <div>
                                    <span className="text-danger">
                                        <FormattedMessage id="The email entered doens't match"/>
                                    </span>
                                </div>
                                :<></>
                              }
                        </div>
                    </div>
                    {
                        !compensate.isSourceAffiliate && <div className="col-md-12">
                            <div className="form-block">
                            <label htmlFor="mobile"><FormattedMessage id="Please provide your Mobile number" /></label>
                                <PhoneInput
                                    international
                                    id='mobile'
                                    countryCallingCodeEditable={false}
                                    defaultCountry="GB"
                                    value={mobileNumber}
                                    onChange={setMobile}
                                    style={{border: '1px solid #001A42', paddingLeft: "10px", borderRadius: "0.375rem" }}
                                />
                                <p className="sig-more-info"><FormattedMessage id="We will send you updates regarding your claim."/></p>
                               {validMobileError ?
                                 <div>
                                    <span className="text-danger">
                                        <FormattedMessage id="Please enter a valid Mobile Number"/>
                                    </span>
                                </div>
                                :<></>
                              }
                                {/*<ListRadioOptions 
                                     className={`d-inline-flex no-radius ps-0`}
                                     items={[
                                         { text: "Would you like to receive claim updates on WhatsApp?", value: true },
                                     ]}
                                     onSelect={() => setWhatsappOptIn(!whatsappOptIn)}
                                     value={whatsappOptIn}
                                    />*/}

                            </div>
                        </div>
                    }
                    {/*<div className="col-md-12">
                        <div className="form-block">
                            <ListRadioOptions
                                label={intl.formatMessage({ id: "Did you book and pay for this flight?" })}
                                items={[{ text: intl.formatMessage({ id:"Yes"}), value: "Yes" }, { text: intl.formatMessage({ id:"No"}), value: "No" }]}
                                onSelect={setdidYouBook}
                                value={didYouBook}
                                className="d-inline-block"
                                divStyle="full-width"
                            />

                               {didubookError && didYouBook === undefined?
                                 <div>
                                    <span className="text-danger">
                                        <FormattedMessage id="Please select an option"/>
                                    </span>
                                </div>
                                :<></>
                              }

                        </div>
                    </div>*/}
                    <i><b><FormattedMessage id="We ask for this information to make sure we can keep you updated on your claim." /></b></i>
                    <div className="col-md-6 text-center mx-auto ">

                        <FormActionButton
                            onNext={() => {
                             if(firstName === '' || firstName === undefined){ setFNameError(true) }
                             if(surname === '' || surname === undefined){ setLnameError(true) }
                             if(dob === '' || dob === undefined){ setdobError(true) }
                             if(!isValidEmail(emailAddress)){setValidEmailError(true)}
                             if(emailAddress !== emailAddressAgain){setEmailDoesntMatch(true)}
                            // if(didYouBook === undefined ||  didYouBook === ''){ setdidubookError(true) }
                                                          
                             if (title && firstName && surname && dob && isValidEmail(emailAddress) && emailAddress === emailAddressAgain && !validMobileError) {
                                if(dob !== undefined){
                                    onNext({
                                        title,
                                        firstName,
                                        surname,
                                        dob,
                                        mobileNumber,
                                        emailAddress,
                                        emailAddressAgain,
                                        didYouBook
                                    }, (COMPENSATE_SCREEN.PASSENGERS + 1))
                                 
                                  }
                                }
                            }}
                        />
                        <br/>
                        <img src={`/img/light-bulb.svg`} className="light-bulb" alt="Light bulb"/><b className='fst-italic'><FormattedMessage id="PASSENGERS encouragement text"/></b>
                    </div>

                </div>
            </div>

        </>
    )
}