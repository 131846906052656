import React, { useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";

const TermsOfUse = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (
        <div className="au-wrapp">
            <Header bg='#EAEAEA' />
            <div className="cp-wrapp gap">
                <div className="container">
                    <h2 className="title text-center">Air Travel Claim Terms of Engagement</h2>
                    <div className="gap-top-sm">
                        <h3 className="title">
                            What WILL Air Travel Claim do for you?
                        </h3>
                        <ul>
                            <li>Upon receipt of your signed Terms, we will conduct a free flight disruption check to try to establish whether you have a valid claim. To enable us to do this we ask you to supply a copy of your booking reference. If you don’t have a copy, we will conduct checks with your airline to try and find out the information about the flight.
                            </li>
                            <li>If we determine that you have a valid Claim(s), we will refer you to our Legal Partner, My Law Matters, who may investigate your Claim(s) against the airline in relation to your and any additional passenger(s) flight delay, denied boarding or flight cancellation Claim(s), including lost, delayed or damaged baggage Claim(s). My Law Matters shall pass information they receive from the airline to us, and we shall conclude the investigation. This is included in the free flight disruption checking service.
                            </li>
                            <li>Where we identify you may be able to make a claim, we will refer you to our law firm partner My Law Matters who will contact your airline to make a claim on your behalf, which may include making a complaint with the airline’s alternative dispute resolution scheme. If your airline defends their position My Law Matters will look to issue Court proceedings against the airline on your behalf</li>
                            <li>
                                My Law Matters is a trading style of Fentiman Legal Ltd. Fentiman Legal Ltd is registered in England and Wales under registration number 12661534, registered office Greville Court Business Centre, 1665 High Street, Knowle, Solihull. B93 0LL authorised and regulated by the Solicitors Regulation Authority (number 800557)
                            </li>
                            <li>
                                Your airline has up to 8 weeks in which to provide a final response, but it is not unusual for the response to take longer than this.
                            </li>
                            <li>
                                We will always act in your best interests when carrying out your flight disruption check and investigating Your Claims(s) In some cases, where airlines will not provide us with the information we need, we will ask our legal partner, My Law Matters to request the information from the airline on your behalf. My Law Matters shall pass information they receive from the airline to us, and we shall conclude the investigation. This is included in the free flight disruption checking service. My Law Matters is a trading style of Fentiman Legal Ltd. Fentiman Legal Ltd is registered in England and Wales under registration number 12661534, registered office Greville Court Business Centre, 1665 High Street, Knowle, Solihull. B93 0LL authorised and regulated by the Solicitors Regulation Authority (number 800557).

                            </li>
                            <li>
                                Your airline has up to 8 weeks in which to provide a final response, but it is not unusual for the response to take longer than this.

                            </li>
                            <li>
                                Where we identify you may be able to make a claim, we will recommend you to our law firm partner My Law Matters who will contact your airline to make a claim on your behalf, which may include making a compliant with the airline’s alternative dispute resolution scheme If your airline defends their position My Law Matters will look to issue Court proceedings against the airline on your behalf.
                            </li>
                        </ul>

                        <h3 className="title">What do we require you to do?</h3>
                        <ul>
                            <li>
                                Provide all relevant information we may request without delay, to enable us to review whether we think you may be eligible to make a Claim(s).

                            </li>
                            <li>
                                Provide us with clear instructions.
                            </li>

                            <li> Not to mislead us, provide false answers to questions or ask us to act in an improper or unreasonable way. </li>
                            <li>To provide us with the authority to act on your behalf. </li>
                            <li>By signing these Terms you are instructing us that you have the appropriate consent of all other additional passengers listed to make the Claim(s) on their behalf and you are agreeing to be the lead passenger. </li>
                        </ul>
                        <h3 className="title">
                            Fees
                        </h3>
                        <ul>


                            <li>We will conduct a free flight disruption checking service.</li>
                            <li>If we discover that you may be eligible to make a flight disruption claim, we will refer you to My Law Matters who can challenge your airline on your behalf. We will not charge you a fee for our work. We may receive payment from My Law Matters for any Claims referred.</li>
                            <li>My Law Matters will charge a fee if your claim is successful.</li>
                            <li>A copy of the My Law Matters terms & conditions are available at: www.airtravelclaim.com/terms-and-conditions/ (which includes information about their fees and cancellation policy)</li>
                        </ul>

                        <h3 className="title">
                            Cancelling this Agreement
                        </h3>
                        <ul>
                            <li> We can cancel this Agreement at any time. There is no cancellation fee if we cancel our agreement to carry out a free flight disruption checking service for you.</li>
                            <li>You have the right to terminate this agreement at any time by giving notice, by emailing info@airtravelclaim.com. Any cancellation must be made by way of a cancellation notice or by a clear statement to us. There is no cancellation fee if you ask us not to go ahead with the free flight disruption checking service.</li>
                            <li>My Law Matters may charge you a cancellation fee if you cancel after the 14-day cooling off period. Please refer to their contract for details of their cancellation fee.</li>
                        </ul>
                        <h3 className="title">Use of Signature</h3>
                        <ul>
                            <li> Should you have a complaint you can contact us by writing to Air Travel Claim at 84 Salop Street Wolverhampton, WV3 0SR, by phoning us on 01902 939000 or by sending an email to compliance@airtravelclaim.com. Full details of our complaints handling procedure can be viewed on our website: https://airtravelclaim.com/complaints-procedure/</li>
                            <li>Should you remain unhappy with our service, you may escalate your complaint within six months to the Financial Ombudsman Service by phone on 0800 023 4567 or post to: Financial Ombudsman Service, Exchange Tower, Harbour Exchange, London, E14 9SR.</li>
                        </ul>
                        <h3 className="title">Data Protection</h3>
                        <ul>
                            <li>We will hold, control, and process your personal information in accordance with the Data Protection Act 2018, the UK General Data Protection Regulations and the Privacy and Electronic Communications Regulations. By providing your personal information to us, you explicitly authorize us to process the information for the purpose set out in this paragraph. You can, at any time, request a copy of all information we hold relating to you by writing to us (a written Data Subject Access Request in accordance with the Data Protection Act/GDPR). We will use the personal information you provide to assess your claim and carry out our duties in accordance to this Agreement. We may share your personal information with other companies if necessary, during the process of your claim for compensation, or any financial matters we believe may be of assistance to you.</li>
                            <li>Your personal information may also be processed by other organizations, including credit reference agencies, on our behalf for the purpose of processing your claims and providing information or services to you. The use of your personal information for these purposes will remain under our control at all times. You can change your mind at any time. Please visit our privacy policy: https://airtravelclaim.com/privacypolicy/</li>
                            <li>Your data was provided to us by Lloyd James Media Ltd. You may opt out of future processing and communications by both Lloyd James Media and Air Travel Claim. To opt out please email to compliance@lloydjames.com or telephone 0800 288 9949 (select option 4, customer services).</li>
                        </ul>
                        <h3 className="title">Introducers</h3>
                        <ul>
                        <li>If you were introduced to us by one of our partners, we will have paid a third-party fee for providing services to you. This fee is NOT payable by you. Further details of any fee paid by us in respect of your case is available upon request. </li>
                        <li>If we introduce you to My Law Matters, we shall receive an introducers fee directly from them. This fee is NOT payable by you.</li>
                        </ul>

                        <h3 className="title">Other Important Information</h3>
                        <ul>
                            <li>
                            You are entitled to seek further advice in relation to your claim and to consider what services might be most appropriate for your claim for compensation. In particular you have the right to shop around, or you can contact your airline directly for free. You should also consider whether you have alternative mechanisms for pursuing a claim, for example, legal expenses insurance.</li>
                            <li>You are under no obligation to make a claim using My Law Matters. You can make a claim to a law firm of your choice.</li>
                            <li>Each party irrevocably agrees that any proceedings relating to any dispute or claim (including non-contractual disputes or claims) arising out of or in connection with this Agreement or its subject matter shall be governed by and construed in accordance with English law and the parties irrevocably submit to the jurisdiction of the Courts of England and Wales.</li>
                            <li>We reserve the right to assign all rights in these Terms and to subcontract to others all or any of our obligations under it.</li>
                            <li>These terms and conditions are accurate as of 23rd November 2022.</li>
                            <li>I/We have read the Terms & Conditions and agree to be bound by their contents. A copy of the terms & conditions can be found on the reverse of the welcome letter. Please keep this document safe, as this is your contract between you and
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default TermsOfUse;