import React from "react";
import { Link } from "react-router-dom";

const InnerBanner = (props) => {
    return (
        <div className="ip-banner-wrapp cover no-repeat" style={{ backgroundImage: 'url("img/faq-banner.png")' }}>
            <div className="b-title"> <Link to="/home">Home</Link>  /  {props.pageName}</div>
        </div>
    )
}

export default InnerBanner;